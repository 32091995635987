import { useState } from 'react';
import firebaseAuthService from './firebase/FirebaseAuthService';
import NavigationBar from './components/pages/navigationPage/NavigationBar';
import { Routes, Route } from 'react-router-dom';
import Home from './components/pages/Tabs/home/Home';
import SignupPage from './components/authentication/SignupPage';
import LoginPage from './components/authentication/LoginPage';
import Error from './components/pages/Error/Error';
import ResetPassword from './components/authentication/ResetPassword';
import AnalysisPage from './components/pages/Tabs/Analysis/Analysis';
import Commercial from './components/pages/Tabs/Commercial/Commercial';
import MonthlyDetails from './components/pages/Tabs/data-lt/MonthlyDetails';
import DataHT from './components/pages/Tabs/data-ht/index.jsx';
import LTAnalysis from './components/pages/Tabs/LT-Analysis';
import HTAnalysis from './components/pages/Tabs/HT-Analysis';


const App = ({ data }) => {

  const [user, setUser] = useState(null);

  firebaseAuthService.subscribeToAuthChanges(setUser);

  return (
    <>
      <Routes>
        <Route element={<NavigationBar existingUser={user} />}>
          <Route exact path='/' element={user ? <Home data={data} existingUser={user} /> : <LoginPage data={data} existingUser={user} />} />
          <Route path='analysisPage' element={<AnalysisPage data={data} existingUser={user} />} />
          <Route path='lt-Analysis' element={<LTAnalysis data={data} existingUser={user} />} />
          <Route path='ht-Analysis' element={<HTAnalysis data={data} existingUser={user} />} />
          <Route path='commercial' element={<Commercial data={data} existingUser={user} />} />
          <Route path='lt-data' element={<MonthlyDetails data={data} existingUser={user} />} />
          <Route path='ht-data' element={<DataHT data={data} existingUser={user} />} />
          <Route path="signup" element={<SignupPage existingUser={user} />} />
          <Route path="login" element={<LoginPage data={data} existingUser={user} />} />
          <Route path="resetPassword" element={<ResetPassword existingUser={user} />} />
          <Route path="*" element={<Error />} />
        </Route>
      </Routes>
    </>
  );

}

export default App;
