import TableLT from './table';
import BeforeLogin from './../../BeforeLogin/BeforeLogin';

function LTAnalysis({ data, existingUser }) {

    return (
        <>
            {
                existingUser ?
                    <>
                        <section className="container py-5 h-100" id='login-section'>
                            {/* <div className="container py-5 h-100"> */}
                                <h1 className='d-flex justify-content-center mb-3'>LT Analysis</h1>
                                <TableLT data={data} />
                            {/* </div> */}
                        </section>
                    </>
                    :
                    <BeforeLogin />
            }
        </>
    )
}

export default LTAnalysis;