import { useState } from 'react';
import BeforeLogin from "../../BeforeLogin/BeforeLogin";
import MonthlyDetailsTable from "./MonthlyDetailsTable/DifferentTableLists/Table/MonthlyDetailsTable";
import DownloadButton from './Download/DownloadButton';
import DownloadSixMonthData from './Download/DownloadSixMonthData';

function MonthlyDetails({ data, existingUser }) {

    const [list, setList] = useState('LTtotalTable');
    const [month, setMonth] = useState(5);

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const d = new Date();
    const date = ' ' + months[d.getMonth()] + ' ' + d.getFullYear();

    function handleMonthChange(event) {
        event.preventDefault();
        setMonth(event.target.value);
        console.log(month);
    };

    const { sequenceLT } = data;

    return (
        <>
            {
                existingUser ?
                    <>
                        <div className='container'>

                            <div className="d-flex justify-content-center mt-2">
                                {/* <h3><span className="">{date}</span></h3> */}
                            </div>

                            {/* <h1 className='d-flex justify-content-center mb-3'>Current Month LT Details</h1>

                            <div className=' d-flex justify-content-end pb-3'>
                                <button className="btn btn-secondary" type="button" data-bs-toggle="offcanvas" data-bs-target="#previousMonthData" aria-controls="previousMonthData">
                                    Download Data of Past Months
                                </button>
                            </div> */}

                            <div className='border  container'>

                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center mt-4">
                                        <li className="page-item"><button value={5} onClick={handleMonthChange} className='page-link text-secondary'>{sequenceLT[5]}</button></li>
                                        <li className="page-item"><button value={4} onClick={handleMonthChange} className='page-link text-secondary'>{sequenceLT[4]}</button></li>
                                        <li className="page-item"><button value={3} onClick={handleMonthChange} className='page-link text-secondary'>{sequenceLT[3]}</button></li>
                                        <li className="page-item"><button value={2} onClick={handleMonthChange} className='page-link text-secondary'>{sequenceLT[2]}</button></li>
                                        <li className="page-item"><button value={1} onClick={handleMonthChange} className='page-link text-secondary'>{sequenceLT[1]}</button></li>
                                    </ul>
                                </nav>

                                <div className='d-flex justify-content-center'>
                                    <h3>{sequenceLT[month]}</h3>
                                </div>

                                <div className='d-flex justify-content-center'>
                                    <div className="form-floating mt-3 mb-3 " id="floatingSelect-listType">
                                        <select className="form-select" onChange={(event) => { setList(event.target.value) }} defaultValue={'LTtotaltable'}>
                                            <option key={5} value={'LTtotalTable'}>Total live LT Connections</option>
                                            <option key={0} value={'LTplus5Table'}>LT Connections with Energy Variations of +5%</option>
                                            <option key={1} value={'LTminus5Table'}>LT Connections with Energy Variations of -5%</option>
                                            <option key={2} value={'LTzeroTable'}>LT Connections with zero Energy Consumption:</option>
                                            <option key={3} value={'LTplusMDSLTable'}>LT Connections with MD-SL {'>'} 20%</option>
                                            <option key={4} value={'LTminusMDSLTable'}>LT Connections with SL-MD {'>'} 10%</option>
                                            <option key={6} value={'LTtotalPowerFactorLessThan9Table'}>LT Connections with power-factor less than 0.8</option>
                                            <option key={7} value={'LTtotalPDCTable'}>LT Connections with PDC</option>
                                        </select>
                                        <label htmlFor="floatingSelect">Specific Connections</label>
                                    </div>
                                </div>
                                <div className=' d-flex justify-content-center pb-2'>
                                    <DownloadButton month={month} data={data} list={list} />
                                </div>


                                <div className="offcanvas offcanvas-start" tabindex="-1" id="previousMonthData" aria-labelledby="offcanvasExampleLabel">
                                    <div className="offcanvas-header">
                                        <h5 className="offcanvas-title" id="offcanvasExampleLabel">Download Previous Month Data</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                    </div>
                                    <div className="offcanvas-body">
                                        <div className='d-flex justify-content-center'>
                                            <table>
                                                <tr className=' d-flex justify-content-center mt-3'>
                                                    <DownloadSixMonthData data={data} list={'first'} />
                                                </tr>
                                                <tr className=' d-flex justify-content-center mt-3'>
                                                    <DownloadSixMonthData data={data} list={'second'} />
                                                </tr>
                                                <tr className=' d-flex justify-content-center mt-3'>
                                                    <DownloadSixMonthData data={data} list={'third'} />
                                                </tr>
                                                <tr className=' d-flex justify-content-center mt-3'>
                                                    <DownloadSixMonthData data={data} list={'fourth'} />
                                                </tr>
                                                <tr className=' d-flex justify-content-center mt-3'>
                                                    <DownloadSixMonthData data={data} list={'fifth'} />
                                                </tr>
                                                <tr className=' d-flex justify-content-center mt-3'>
                                                    <DownloadSixMonthData data={data} list={'sixth'} />
                                                </tr>
                                                <tr className=' d-flex justify-content-center mt-3'>
                                                    <DownloadSixMonthData data={data} list={'seventh'} />
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <MonthlyDetailsTable data={data} month={month} list={list} />
                            </div>
                        </div>
                    </>
                    :
                    <BeforeLogin />
            }
        </>
    )
}

export default MonthlyDetails;