import { CSVLink } from "react-csv";



function DownloadButton({ data, list }) {

    const { sequenceLT,first, second, third, fourth, fifth, sixth, sortObject } = data;


    return (
        <>
            {
                (list === 'first') ?
                    <CSVLink filename={sequenceLT[5]} className='btn btn-secondary' data={sortObject(first)}>Download Details for {first[0].billMonthYear}</CSVLink>
                    :
                    (list === 'second') ?
                        <CSVLink filename={sequenceLT[4]} className='btn btn-secondary' data={sortObject(second)}>Download Details for {second[0].billMonthYear}</CSVLink>
                        :
                        (list === 'third') ?
                            <CSVLink filename={sequenceLT[3]} className='btn btn-secondary' data={sortObject(third)}>Download Details for {third[0].billMonthYear}</CSVLink>
                            :
                            (list === 'fourth') ?
                                <CSVLink filename={sequenceLT[2]} className='btn btn-secondary' data={sortObject(fourth)}>Download Details for {fourth[0].billMonthYear}</CSVLink>
                                :
                                (list === 'fifth') ?
                                    <CSVLink filename={sequenceLT[1]} className='btn btn-secondary' data={sortObject(fifth)}>Download Details for {fifth[0].billMonthYear}</CSVLink>
                                    :
                                    (list === 'sixth') ?
                                        <CSVLink filename={sequenceLT[0]} className='btn btn-secondary' data={sortObject(sixth)}>Download Details for {sixth[0].billMonthYear}</CSVLink>
                                        :
                                        null

            }
        </>
    )
}

export default DownloadButton;