import Tr from '../../../../components/table/tr'


export default function TableHT({ data }) {
    const { sequenceHT, HTcurrentMonthTotalBill, HTtotalBill, HTtotalConsumption, HTtotalFixedCharges, HTtotalPowerFactorLessThan9List, HTpowerFactorLessThan9TotalWeldingCharge, HTfirst, HTsecond, HTthird, HTfourth, HTfifth, HTsixth, HTplus5List, HTminus5List, HTplusMDSLList, HTminusMDSLList, HTtotalLoad, HTtotalContractDemand } = data;
    const buildConnectionsList = [HTfirst.length, HTsecond.length, HTthird.length, HTfourth.length, HTfifth.length, HTsixth.length].reverse();
    return (
        <>
            <div className="mx-auto container-fluid d-flex justify-content-center">
                <table id='analysis-table' className="mx-auto w-75 table table-hover table-secondary table-bordered table-striped border border-secondary pt-5">
                    <tbody>
                        <tr>
                            <td className='fs-4' colSpan={3}><h3 className='d-flex justify-content-center'><span className="">{data.sequenceHT[5]}</span></h3></td>
                        </tr>
                        <Tr sequence={sequenceHT} show={['Connections']} order={'kly'} list={buildConnectionsList} secondList={''} division={1} data={buildConnectionsList[5]} >Total Billed Connections:</Tr>
                        <Tr sequence={sequenceHT} show={['Cr kWh']} order={'yoyo'} list={HTtotalConsumption} secondList={''} division={10000000} data={HTtotalConsumption[5]}>Total Consumption (kWh):</Tr>
                        <Tr sequence={sequenceHT} show={['Cr Rs']} order={'thirteen'} list={HTcurrentMonthTotalBill} secondList={''} division={10000000} data={HTcurrentMonthTotalBill[5]}>Total Month Bill (Rs):</Tr>
                        <Tr sequence={sequenceHT} show={['Cr Rs']} order={'twelve'} list={HTtotalBill} secondList={''} division={10000000} data={HTtotalBill[5]}>Total Amount Due (Rs):</Tr>
                        <tr></tr>
                        <tr>
                            <td className='fs-4' colSpan={3}><h3 className='d-flex justify-content-center'><span className="">Load Analysis</span></h3></td>
                        </tr>

                        <Tr sequence={sequenceHT} show={['Maximum Demand (MW)', 'Contract Demand (MW)']} order={'jhj'} list={HTtotalLoad} secondList={HTtotalContractDemand} division={1000} data={HTtotalLoad[5]} >Total Load (kW):</Tr>
                        <Tr sequence={sequenceHT} show={['Cr Rs']} order={'seventeen'} list={HTtotalFixedCharges} secondList={''} division={10000000} data={parseInt(HTtotalFixedCharges[5])}>Fixed Charges (Rs):</Tr>
                        <Tr sequence={sequenceHT} show={['Connections']} order={'eight'} list={HTplusMDSLList} secondList={''} division={1} data={HTplusMDSLList[4].length} >Connections with MD-CD {'>'} 20%:</Tr>
                        <Tr sequence={sequenceHT} show={['Connections']} order={'nine'} list={HTminusMDSLList} secondList={''} division={1} data={HTminusMDSLList[4].length} >Connections with CD-MD {'>'} 10%:</Tr>
                        <tr></tr>
                        <tr>
                            <td className='fs-4' colSpan={3}><h3 className='d-flex justify-content-center'><span className="">PF Analysis</span></h3></td>
                        </tr>
                        <Tr sequence={sequenceHT} show={['Connections']} order={'fifteen'} list={HTtotalPowerFactorLessThan9List} secondList={''} division={1} data={HTtotalPowerFactorLessThan9List[5].length}>Connections with  PF {'<'} 0.9:</Tr>
                        <Tr sequence={sequenceHT} show={['lakh Rs']} order={'sixteen'} list={HTpowerFactorLessThan9TotalWeldingCharge} secondList={''} division={100000} data={parseInt(HTpowerFactorLessThan9TotalWeldingCharge[5])}>Total PF Surcharge (Rs):</Tr>
                        <tr></tr>
                        <tr>
                            <td className='fs-4' colSpan={3}><h3 className='d-flex justify-content-center'><span className="">Consumption Analysis</span></h3></td>
                        </tr>
                        <Tr sequence={sequenceHT} show={['Connections']} order={'six'} list={HTplus5List} secondList={''} division={1} data={HTplus5List[4].length} >Connections with Consumption +5%:</Tr>
                        <Tr sequence={sequenceHT} show={['Connections']} order={'seven'} list={HTminus5List} secondList={''} division={1} data={HTminus5List[4].length} >Connections with Consumption -5%:</Tr>


                    </tbody>
                </table>
            </div>
        </>
    )
}