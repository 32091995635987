import { useState } from 'react';
import BeforeLogin from './../../BeforeLogin/BeforeLogin';
import { Link } from 'react-router-dom';
import MonthDataTableLT from './MonthDataDetails/LT/MonthDataTable';
import MonthDataChartLT from './MonthDataDetails/LT/MonthDataChart';
import MonthDataTableHT from './MonthDataDetails/HT/MonthDataTable';
import MonthDataChartHT from './MonthDataDetails/HT/MonthDataChart';


function Home({ data, existingUser }) {

    const {
        //*LT
        sequenceLT,
        first,
        LTtotalConsumption,
        LTtotalLoad,
        LTcurrentMonthTotalBill,
        //*HT
        sequenceHT,
        HTcurrentMonthTotalBill,
        HTupdatedFirst,
        HTtotalConsumption,
        HTtotalLoad,
    } = data;

    const [state, setState] = useState();
    const [connectionNumber, setConnectionNumber] = useState();
    const [connectionType, setConnectionType] = useState();

    function handleChange(event) {
        event.preventDefault();
        setState(event.target.value);
    }

    function handleConnectionNumber(event) {
        event.preventDefault();
        const list = first.concat(HTupdatedFirst);
        const properNumber = list.find((x) => {
            if (x.accountId === state) {
                setConnectionType('LT')
                return x;
            }
            setConnectionType('HT')
            return (x.consumerNo === state);
        });
        if (properNumber) {
            setConnectionNumber(properNumber);
        } else {
            alert('Please enter a valid IVRS Number');
        }
    };

    return (
        <>
            {
                existingUser ?
                    <>
                        {/* //todo: LT Section */}

                        <section className="container" id='login-section'>

                            {/* <div className="d-flex justify-content-center mt-2">
                                <h3><span className="">{date}</span></h3>
                            </div> */}

                            <div className="container py-5 h-100">
                                {/* //*: Heading */}
                                <div className="d-flex justify-content-center">
                                    <h2><span className=" p-4 mb-4">ELECTRIC SECTION</span></h2>
                                </div>


                                <div className="d-flex justify-content-center">
                                    <h2><span className=" mb-4">POWER BILL OPTIMIZATION</span></h2>
                                </div>

                                <table style={{ width: '20rem' }} className="mx-auto table table-responsive table-borderless mt-4">

                                    <tbody>
                                        <tr>
                                            <td><button className="btn btn-secondary "><Link className='contact-text-link fw-bold fs-5' to='/LT-Analysis'>LT-Analysis</Link></button></td>
                                            <td><button className="btn btn-secondary "><Link className='contact-text-link fw-bold fs-5' to='/HT-Analysis'>HT-Analysis</Link></button></td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div className="d-flex justify-content-center mt-5">
                                    <h2><span className=" mb-4">LT DATA</span></h2>
                                </div>


                                {/* //*:DATA */}

                                {/* <div className="d-flex flex-row justify-content-center align-content-center"> */}
                                <table style={{ width: '15rem' }} className="mx-auto table table-responsive table-bordered">
                                    <tbody>
                                        <tr>
                                            <td className='bg-secondary' colSpan={2}><h3 className='d-flex justify-content-center'><span className=" badge bg-secondary">{sequenceLT[5]}</span></h3></td>
                                        </tr>
                                        <tr>
                                            <td className='bg-secondary'><h4><span className=" badge bg-secondary">Total Connections:</span></h4></td>
                                            <td className='bg-secondary'><h4><span className=" badge bg-secondary">{first.length.toLocaleString("en-IN")}</span></h4></td>
                                        </tr>
                                        <tr>
                                            <td className='bg-secondary'><h4><span className=" badge bg-secondary">Total Load:</span></h4></td>
                                            <td className='bg-secondary'><h4><span className=" badge bg-secondary">{LTtotalLoad[5].toLocaleString("en-IN")} kW</span></h4></td>
                                        </tr>
                                        <tr>
                                            <td className='bg-secondary'><h4><span className=" badge bg-secondary">Total Energy:</span></h4></td>
                                            <td className='bg-secondary'><h4><span className=" badge bg-secondary">{LTtotalConsumption[5].toLocaleString("en-IN")} kWh</span></h4></td>
                                        </tr>
                                        <tr>
                                            <td className='bg-secondary'><h4><span className=" badge bg-secondary">Bill Amount:</span></h4></td>
                                            <td className='bg-secondary'><h4><span className=" badge bg-secondary">{LTcurrentMonthTotalBill[5].toLocaleString("en-IN")} Rs</span></h4></td>
                                        </tr>
                                    </tbody>
                                </table>

                                {/*//todo: HT DATA */}

                                <div className="d-flex justify-content-center mt-5">
                                    <h2><span className=" mb-4">HT DATA</span></h2>
                                </div>

                                <table style={{ width: '15rem' }} className="mx-auto table table-responsive table-bordered mb-5">
                                    <tbody>
                                        <tr>
                                            <td className='bg-secondary' colSpan={2}><h3 className='d-flex justify-content-center'><span className=" badge bg-secondary">{sequenceHT[5]}</span></h3></td>
                                        </tr>
                                        <tr>
                                            <td className='bg-secondary'><h4><span className=" badge bg-secondary">Total Connections:</span></h4></td>
                                            <td className='bg-secondary'><h4><span className=" badge bg-secondary">{HTupdatedFirst.length.toLocaleString("en-IN")}</span></h4></td>
                                        </tr>
                                        <tr>
                                            <td className='bg-secondary'><h4><span className=" badge bg-secondary">Total Load:</span></h4></td>
                                            <td className='bg-secondary'><h4><span className=" badge bg-secondary">{HTtotalLoad[5].toLocaleString("en-IN")} kW</span></h4></td>
                                        </tr>
                                        <tr>
                                            <td className='bg-secondary'><h4><span className=" badge bg-secondary">Total Energy:</span></h4></td>
                                            <td className='bg-secondary'><h4><span className=" badge bg-secondary">{HTtotalConsumption[5].toLocaleString("en-IN")} kWh</span></h4></td>
                                        </tr>
                                        <tr>
                                            <td className='bg-secondary'><h4><span className=" badge bg-secondary">Bill Amount:</span></h4></td>
                                            <td className='bg-secondary'><h4><span className=" badge bg-secondary">{HTcurrentMonthTotalBill[5].toLocaleString("en-IN")} Rs</span></h4></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr />
                                <div className='container d-flex justify-content-center mt-5'>
                                    <form onSubmit={handleConnectionNumber}>
                                        <div className="mb-3">
                                            <label htmlFor="connectionNumberHT" className="form-label d-flex justify-content-center"><h3>Connection Details</h3></label>
                                            <input placeholder='IVRS Number' autoCapitalize="character" type="text" className="form-control" id="connectionNumberHT" value={state || ''} onChange={handleChange} />
                                            <div className='d-flex justify-content-center mt-3'>
                                                <button type="submit" className="btn btn-secondary d-flex align-content-center">Show Details</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                {
                                    connectionNumber ?
                                        <>
                                            <div className='graph mt-4 mb-4'>
                                                {
                                                    connectionType === 'LT' ?
                                                        <MonthDataChartLT data={data} x={connectionNumber} />
                                                        :
                                                        <MonthDataChartHT data={data} x={connectionNumber} />
                                                }
                                            </div>
                                            <div className='container graph mt-4 pt-1 pb-2'>
                                                <div className='graph mt-4'>
                                                    {
                                                        connectionType === 'LT' ?
                                                            <>
                                                                <h1 className="contact-text d-flex justify-content-center">{connectionNumber.accountId} Full Details</h1>
                                                                <MonthDataTableLT x={connectionNumber} />
                                                            </>
                                                            :
                                                            <>
                                                                <h1 className="contact-text d-flex justify-content-center">{connectionNumber.consumerNo} Full Details</h1>
                                                                <MonthDataTableHT x={connectionNumber} />
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                        :
                                        null
                                }
                            </div>
                        </section>
                    </>
                    :
                    <BeforeLogin />
            }
        </>
    )
}


export default Home;