import { CSVLink } from "react-csv";



function DownloadButton({ month, data, list }) {

    const { LTplus5List, LTminus5List, LTzeroList, LTplusMDSLList, LTminusMDSLList, first, second, third, fourth, fifth, sixth, LTtotalPowerFactorLessThan9List, LTtotalPDCList, sortObject } = data;

    const monthList = [sixth, fifth, fourth, third, second, first];
    return (
        <>
            {
                (list === 'LTplus5Table') ?
                    <CSVLink filename="bmc-data" className='btn btn-secondary' data={sortObject(LTplus5List[4])}>Download Details for above list</CSVLink>
                    :
                    (list === 'LTminus5Table') ?
                        <CSVLink filename="bmc-data" className='btn btn-secondary' data={sortObject(LTminus5List[4])}>Download Details for above list</CSVLink>
                        :
                        (list === 'LTzeroTable') ?
                            <CSVLink filename="bmc-data" className='btn btn-secondary' data={sortObject(LTzeroList[5])}>Download Details for above list</CSVLink>
                            :
                            (list === 'LTplusMDSLTable') ?
                                <CSVLink filename="bmc-data" className='btn btn-secondary' data={sortObject(LTplusMDSLList[5])}>Download Details for above list</CSVLink>
                                :
                                (list === 'LTminusMDSLTable') ?
                                    <CSVLink filename="bmc-data" className='btn btn-secondary' data={sortObject(LTminusMDSLList[5])}>Download Details for above list</CSVLink>
                                    :
                                    (list === 'LTtotalTable') ?
                                        <CSVLink filename="bmc-data" className='btn btn-secondary' data={sortObject(monthList[month])}>Download Details for above list</CSVLink>
                                        :
                                        (list === 'LTtotalPowerFactorLessThan9Table') ?
                                            <CSVLink filename="bmc-data" className='btn btn-secondary' data={sortObject(LTtotalPowerFactorLessThan9List[5])}>Download Details for above list</CSVLink>
                                            :
                                            (list === 'LTtotalPDCTable') ?
                                                <CSVLink filename="bmc-data" className='btn btn-secondary' data={sortObject(LTtotalPDCList[5])}>Download Details for above list</CSVLink>
                                                :
                                                null

            }
        </>
    )
}

export default DownloadButton;