import { Line } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";


function MonthDataChart({ data, x }) {


    const { sequence, HTsecond, HTthird, HTfourth, HTfifth, HTsixth } = data;
    const one = x;

    const two = HTsecond.find((y) => {
        return (y.consumerNo === x.consumerNo);
    });
    const three = HTthird.find((y) => {
        return (y.consumerNo === x.consumerNo);
    });
    const four = HTfourth.find((y) => {
        return (y.consumerNo === x.consumerNo);
    });
    const five = HTfifth.find((y) => {
        return (y.consumerNo === x.consumerNo);
    });
    const six = HTsixth.find((y) => {
        return (y.consumerNo === x.consumerNo);
    });

    const state = {
        dataLine: {
            labels: sequence,
            datasets: [
                {
                    label: 'kWh',
                    fill: true,
                    lineTension: 0,
                    backgroundColor: "rgb(253,95,0,0.3)",
                    borderColor: "rgb(253,95,0)",
                    borderCapStyle: "butt",
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: "miter",
                    pointBorderColor: "grey",
                    pointBackgroundColor: "rgb(255, 255, 255)",
                    pointBorderWidth: 10,
                    pointHoverRadius: 10,
                    pointHoverBackgroundColor: "floralwhite",
                    pointHoverBorderColor: "rgba(220, 220, 220,1)",
                    pointHoverBorderWidth: 2,
                    pointRadius: 2,
                    pointHitRadius: 5,
                    data: [
                        six.totalUnitsKwh,
                        five.totalUnitsKwh,
                        four.totalUnitsKwh,
                        three.totalUnitsKwh,
                        two.totalUnitsKwh,
                        one.totalUnitsKwh
                    ]
                }
            ]
        }
    };

    return (
        <>
            <div className='container pt-3 pb-4 '>
                <MDBContainer className="p-3">
                    <h1 className="contact-text d-flex justify-content-center">{x.consumerNo}: Consumption History</h1>
                    <div className="displayGraph">
                        <Line data={state.dataLine} options={{ responsive: true }} />
                    </div>
                </MDBContainer>
            </div>
        </>
    )
}

export default MonthDataChart;




