import { useState } from 'react';
import BeforeLogin from "../../BeforeLogin/BeforeLogin";
import Table from "./table/index";
import DownloadButton from './table/Download/index';
import DownloadSixMonthData from './table/Download/specificMonth/index';


function HTData({ data, existingUser }) {

    const [list, setList] = useState('total');
    const [month, setMonth] = useState(5);

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const d = new Date();
    const date = ' ' + months[d.getMonth()] + ' ' + d.getFullYear();

    function handleMonthChange(event) {
        event.preventDefault();
        setMonth(event.target.value);
        console.log(month);
    };

    const { sequenceHT } = data;

    return (
        <>
            {
                existingUser ?
                    <>
                        <div className='container'>

                            {/* <div className="d-flex justify-content-center mt-2">
                                <h3><span className="">{date}</span></h3>
                            </div>
                            <h1 className='d-flex justify-content-center mb-3'>Current Month HT Details</h1>

                            <div className=' d-flex justify-content-end pb-3'>
                                <button className="btn btn-secondary" type="button" data-bs-toggle="offcanvas" data-bs-target="#previousMonthData" aria-controls="previousMonthData">
                                    Download Data of Past Months
                                </button>
                            </div> */}

                            <div className='border  container'>

                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center mt-4">
                                        <li className="page-item"><button value={5} onClick={handleMonthChange} className='page-link text-secondary'>{sequenceHT[5]}</button></li>
                                        <li className="page-item"><button value={4} onClick={handleMonthChange} className='page-link text-secondary'>{sequenceHT[4]}</button></li>
                                        <li className="page-item"><button value={3} onClick={handleMonthChange} className='page-link text-secondary'>{sequenceHT[3]}</button></li>
                                        <li className="page-item"><button value={2} onClick={handleMonthChange} className='page-link text-secondary'>{sequenceHT[2]}</button></li>
                                        <li className="page-item"><button value={1} onClick={handleMonthChange} className='page-link text-secondary'>{sequenceHT[1]}</button></li>
                                    </ul>
                                </nav>

                                <div className='d-flex justify-content-center'>
                                    <h3>{sequenceHT[month]}</h3>
                                </div>

                                <div className='d-flex justify-content-center'>
                                    <div className="form-floating mt-3 mb-3 " id="floatingSelect-listType">
                                        <select className="form-select" onChange={(event) => { setList(event.target.value) }} defaultValue={'total'}>
                                            <option key={0} value={'plus5'}> Connections with Energy Variations of +5%</option>
                                            <option key={1} value={'minus5'}> Connections with Energy Variations of -5%</option>
                                            <option key={2} value={'plusmdsl'}> Connections with MD-CD {'>'} 20%</option>
                                            <option key={3} value={'plusslmd'}> Connections with CD-MD {'>'} 10% </option>
                                            <option key={4} value={'total'}>Total live  Connections</option>
                                            <option key={5} value={'pf'}> Connections with power-factor less than 0.9</option>
                                        </select>
                                        <label htmlFor="floatingSelect">Specific Connections</label>
                                    </div>
                                </div>

                                <div className=' d-flex justify-content-center pb-2'>
                                    <DownloadButton month={month} data={data} list={list} />
                                </div>

                                {/* 
                                <div className="offcanvas offcanvas-start" tabindex="-1" id="previousMonthData" aria-labelledby="offcanvasExampleLabel">
                                    <div className="offcanvas-header">
                                        <h5 className="offcanvas-title" id="offcanvasExampleLabel">Download Previous Month Data</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                    </div>

                                    <div className="offcanvas-body">
                                        <div className='d-flex justify-content-center'>
                                            <table>
                                                <tr className=' d-flex justify-content-center mt-3'>
                                                    <DownloadSixMonthData data={data} list={'firstHT'} />
                                                </tr>
                                                <tr className=' d-flex justify-content-center mt-3'>
                                                    <DownloadSixMonthData data={data} list={'secondHT'} />
                                                </tr>
                                                <tr className=' d-flex justify-content-center mt-3'>
                                                    <DownloadSixMonthData data={data} list={'thirdHT'} />
                                                </tr>
                                                <tr className=' d-flex justify-content-center mt-3'>
                                                    <DownloadSixMonthData data={data} list={'fourthHT'} />
                                                </tr>
                                                <tr className=' d-flex justify-content-center mt-3'>
                                                    <DownloadSixMonthData data={data} list={'fifthHT'} />
                                                </tr>
                                                <tr className=' d-flex justify-content-center mt-3'>
                                                    <DownloadSixMonthData data={data} list={'sixthHT'} />
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                </div> */}

                                <Table month={month} data={data} list={list} />
                            </div>
                        </div>
                    </>
                    :
                    <BeforeLogin />
            }
        </>
    )
}

export default HTData;