import { Line } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";


function Graph({ list, division, sequence, show, secondList }) {
    console.log(secondList);
    let state;
    if (!secondList.length) {
        state = {
            dataLine: {
                labels: sequence.slice(0, list.length),
                datasets: [
                    {
                        label: show[0],
                        fill: true,
                        lineTension: 0,
                        backgroundColor: "rgb(0,0,0,0)",
                        borderColor: "rgb(253,95,0)",
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "grey",
                        pointBackgroundColor: "rgb(255, 255, 255)",
                        pointBorderWidth: 10,
                        pointHoverRadius: 10,
                        pointHoverBackgroundColor: "floralwhite",
                        pointHoverBorderColor: "rgba(220, 220, 220,1)",
                        pointHoverBorderWidth: 2,
                        pointRadius: 2,
                        pointHitRadius: 5,
                        data: Array.isArray(list[0]) ? list.map((x) => { return x.length / division }) : list.map((x) => { return x / division })
                    }
                ]
            }
        };
    } else if(secondList.length){
        state = {
            dataLine: {
                labels: sequence.slice(0, list.length),
                datasets: [
                    {
                        label: show[0],
                        fill: true,
                        lineTension: 0,
                        backgroundColor: "rgb(0,0,0,0)",
                        borderColor: "rgb(253,95,0)",
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "grey",
                        pointBackgroundColor: "rgb(255, 255, 255)",
                        pointBorderWidth: 10,
                        pointHoverRadius: 10,
                        pointHoverBackgroundColor: "floralwhite",
                        pointHoverBorderColor: "rgba(220, 220, 220,1)",
                        pointHoverBorderWidth: 2,
                        pointRadius: 2,
                        pointHitRadius: 5,
                        data: Array.isArray(list[0]) ? list.map((x) => { return x.length / division }) : list.map((x) => { return x / division })
                    },
                    {
                        label: show[1],
                        fill: true,
                        lineTension: 0,
                        backgroundColor: "rgb(0,0,0,0)",
                        borderColor: "green",
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "grey",
                        pointBackgroundColor: "rgb(255, 255, 255)",
                        pointBorderWidth: 10,
                        pointHoverRadius: 10,
                        pointHoverBackgroundColor: "floralwhite",
                        pointHoverBorderColor: "rgba(220, 220, 220,1)",
                        pointHoverBorderWidth: 2,
                        pointRadius: 2,
                        pointHitRadius: 5,
                        data: Array.isArray(secondList[0]) ? secondList.map((x) => { return x.length / division }) : secondList.map((x) => { return x / division })
                    },
                    {
                        label: '',
                        fill: true,
                        lineTension: 0,
                        backgroundColor: "rgb(0,0,0,0)",
                        borderColor: "rgb(0,0,0,0)",
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "rgb(0,0,0,0)",
                        pointBackgroundColor: "rgb(0,0,0,0)",
                        pointBorderWidth: 10,
                        pointHoverRadius: 10,
                        pointHoverBackgroundColor: "rgb(0,0,0,0)",
                        pointHoverBorderColor: "rgb(0,0,0,0)",
                        pointHoverBorderWidth: 2,
                        pointRadius: 2,
                        pointHitRadius: 5,
                        data: Array.isArray(list[0]) ? list.map((x) => { return (x.length / division)-10 }) : list.map((x) => { return (x / division)-10 })
                    }
                ]
            }
        };
    }

    return (
        <>
            <div className='container py-3 bg-secondary rounded'>
                <MDBContainer className="d-flex justify-content-center">
                    <div className="tableGraphs w-100 h-100">
                        <Line data={state.dataLine} options={{ responsive: true }} />
                    </div>
                </MDBContainer>
            </div>
        </>
    )
}

export default Graph;




