import { Line } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";
import { useState } from "react";

function MonthDataChart({ data, x }) {

    const [show, setShow] = useState('totalConsumption');

    const { sequenceLT, second, third, fourth, fifth, sixth } = data;
    const one = x;

    const two = second.find((y) => {
        return (y.accountId === x.accountId);
    });
    const three = third.find((y) => {
        return (y.accountId === x.accountId);
    });
    const four = fourth.find((y) => {
        return (y.accountId === x.accountId);
    });
    const five = fifth.find((y) => {
        return (y.accountId === x.accountId);
    });
    const six = sixth.find((y) => {
        return (y.accountId === x.accountId);
    });

    const state = {
        dataLine: {
            labels: sequenceLT,
            datasets: [
                {
                    label: '',
                    fill: true,
                    lineTension: 0,
                    backgroundColor: "rgb(0,0,0,0)",
                    borderColor: "rgb(253,95,0)",
                    borderCapStyle: "butt",
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: "miter",
                    pointBorderColor: "grey",
                    pointBackgroundColor: "rgb(255, 255, 255)",
                    pointBorderWidth: 10,
                    pointHoverRadius: 10,
                    pointHoverBackgroundColor: "floralwhite",
                    pointHoverBorderColor: "rgba(220, 220, 220,1)",
                    pointHoverBorderWidth: 2,
                    pointRadius: 2,
                    pointHitRadius: 5,
                    data: [
                        six[show],
                        five[show],
                        four[show],
                        three[show],
                        two[show],
                        one[show]
                    ]
                }
            ]
        }
    };

    return (
        <>
            <div className='container pb-4 '>
                <div className="form-floating mt-3" id="floatingSelect-connection">
                    <select className="form-select" aria-label="Floating label select example" onChange={(e) => setShow(e.target.value)} defaultValue={'Billing'}>
                        <option value={'totalConsumption'}>Consumption(Kwh)</option>
                        <option value={'maxDemand'}>Maximum Demand(kW)</option>
                        <option value={'powerFactor'}>Power Factor(PF)</option>
                    </select>
                    <label htmlFor="floatingSelect">Select</label>
                </div>
                <MDBContainer className="">
                    {/* <p className=" d-flex justify-content-center">Consumption History Graph</p> */}
                    <div className="displayGraph">
                        <Line data={state.dataLine} options={{ responsive: true }} />
                    </div>
                </MDBContainer>
            </div>
        </>
    )
}

export default MonthDataChart;




