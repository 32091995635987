import Tr from "./tr";

function CommercialDetails({ data }) {

    const { sequenceHT, HTcurrentMonthTotalBill, HTtotalBill, HTtotalConsumption, HTtotalFixedCharges, HTsurchargePayableOnDelay, HTtotalPowerFactorLessThan9List, HTpowerFactorLessThan9TotalWeldingCharge, HTfirst, HTsecond, HTthird, HTfourth, HTfifth, HTsixth } = data;
    const buildConnectionsList = [HTfirst.length, HTsecond.length, HTthird.length, HTfourth.length, HTfifth.length, HTsixth.length].reverse();
    return (
        <>
            <div className="container d-flex justify-content-center">
                <table id='analysis-table' className="table table-hover table-secondary table-bordered table-striped border border-secondary mt-3 pt-5">
                    <tbody>
                        <tr>
                            <td className='fs-4' colSpan={3}><h3 className='d-flex justify-content-center'><span className="">{sequenceHT[5]}</span></h3></td>
                        </tr>
                        <Tr sequence={sequenceHT} show='Connections' order={'klz'} list={buildConnectionsList} division={1} data={buildConnectionsList[5]} >Total Billed Connections:</Tr>
                        <Tr sequence={sequenceHT} show='Cr Rs' order={'twelve'} list={HTtotalBill} division={10000000} data={HTtotalBill[5]}>Total Amount Due (Rs):</Tr>
                        <Tr sequence={sequenceHT} show='Cr Rs' order={'thirteen'} list={HTcurrentMonthTotalBill} division={10000000} data={HTcurrentMonthTotalBill[5]}>Current Month Bill (Rs):</Tr>
                        <Tr sequence={sequenceHT} show='Cr Rs' order={'seventeen'} list={HTtotalFixedCharges} division={10000000} data={parseInt(HTtotalFixedCharges[5])}>Fixed Charges (Rs):</Tr>
                        <Tr sequence={sequenceHT} show='Connections' order={'fifteen'} list={HTtotalPowerFactorLessThan9List} division={1} data={HTtotalPowerFactorLessThan9List[5].length}>Connections with  PF {'<'} 0.9:</Tr>
                        <Tr sequence={sequenceHT} show='lakh Rs' order={'sixteen'} list={HTpowerFactorLessThan9TotalWeldingCharge} division={100000} data={parseInt(HTpowerFactorLessThan9TotalWeldingCharge[5])}>Total PF Surcharge (Rs):</Tr>

                    </tbody>
                </table>
            </div>
        </>
    )
}

export default CommercialDetails;  