import { CSVLink } from "react-csv";



function DownloadButton({ data, month,list }) {

    const { HTupdatedFirst, HTplus5List, HTminus5List, HTplusMDSLList, HTminusMDSLList, HTtotalPowerFactorLessThan9List, sortObject, HTsecond, HTthird, HTfourth, HTfifth, HTsixth } = data;
    const monthList = [HTupdatedFirst, HTsecond, HTthird, HTfourth, HTfifth, HTsixth].reverse();

    return (
        <>
            {
                (list === 'plus5') ?
                    <CSVLink filename="bmc-data" className='btn btn-secondary' data={sortObject(HTplus5List[month-1])}>Download Details for above list</CSVLink>
                    :
                    (list === 'minus5') ?
                        <CSVLink filename="bmc-data" className='btn btn-secondary' data={sortObject(HTminus5List[month-1])}>Download Details for above list</CSVLink>
                        :
                        (list === 'plusmdsl') ?
                            <CSVLink filename="bmc-data" className='btn btn-secondary' data={sortObject(HTplusMDSLList[month])}>Download Details for above list</CSVLink>
                            :
                            (list === 'plusslmd') ?
                                <CSVLink filename="bmc-data" className='btn btn-secondary' data={sortObject(HTminusMDSLList[month])}>Download Details for above list</CSVLink>
                                :
                                (list === 'total') ?
                                    <CSVLink filename="bmc-data" className='btn btn-secondary' data={sortObject(monthList[month])}>Download Details for above list</CSVLink>
                                    :
                                    (list === 'pf') ?
                                        <CSVLink filename="bmc-data" className='btn btn-secondary' data={sortObject(HTtotalPowerFactorLessThan9List[month])}>Download Details for above list</CSVLink>
                                        :
                                        null

            }
        </>
    )
}

export default DownloadButton;