import LTplus5Table from "../LTplus5Table";
import LTminus5Table from "../LTminus5Table";
import LTzeroTable from "../LTzeroTable";
import LTplusMDSLTable from "../LTplusMDSLTable";
import LTminusMDSLTable from "../LTminusMDSLTable";
import LTtotalTable from "../LTtotalTable";
import LTtotalPowerFactorLessThan9Table from '../LTtotalPowerFactorLessThan9Table';
import LTtotalPDCTable from '../LTtotalPDCTable';

function MonthlyDetailsTable({ data, month, list }) {

    const { LTplus5List, LTminus5List, LTzeroList, LTplusMDSLList, LTminusMDSLList, first, second, third, fourth, fifth, sixth, LTtotalPowerFactorLessThan9List, LTtotalPDCList } = data;
    const monthList = [sixth, fifth, fourth, third, second, first];

    return (
        <>
            {
                (list === 'LTplus5Table' && <LTplus5Table data={data} list={LTplus5List[month - 1]} />)
                    ?
                    <LTplus5Table data={data} list={LTplus5List[month - 1]} />
                    :
                    (list === 'LTminus5Table' && <LTminus5Table data={data} list={LTminus5List[month - 1]} />)
                        ?
                        <LTminus5Table data={data} list={LTminus5List[month - 1]} />
                        :
                        (list === 'LTzeroTable' && <LTzeroTable data={data} list={LTzeroList[month]} />)
                            ?
                            <LTzeroTable data={data} list={LTzeroList[month]} />
                            :
                            (list === 'LTplusMDSLTable' && <LTplusMDSLTable data={data} list={LTplusMDSLList[month]} />)
                                ?
                                <LTplusMDSLTable data={data} list={LTplusMDSLList[month]} />
                                :
                                (list === 'LTminusMDSLTable' && <LTminusMDSLTable data={data} list={LTminusMDSLList[month]} />)
                                    ?
                                    <LTminusMDSLTable data={data} list={LTminusMDSLList[month]} />
                                    :
                                    (list === 'LTtotalTable' && <LTtotalTable data={data} list={monthList[month]} />)
                                        ?
                                        <LTtotalTable data={data} list={monthList[month]} />
                                        :
                                        (list === 'LTtotalPowerFactorLessThan9Table' && <LTtotalPowerFactorLessThan9Table data={data} list={LTtotalPowerFactorLessThan9List[month]} />)
                                            ?
                                            <LTtotalPowerFactorLessThan9Table data={data} list={LTtotalPowerFactorLessThan9List[month]} />
                                            :
                                            (list === 'LTtotalPDCTable' && <LTtotalPDCTable data={data} list={LTtotalPDCList[month]} />)
                                                ?
                                                <LTtotalPDCTable data={data} list={LTtotalPDCList[month]} />
                                                :
                                                null
            }
        </>
    )
}

export default MonthlyDetailsTable;
