import Total from './specificTables/total';
import Plus5 from './specificTables/plus5';
import Minus5 from './specificTables/minus5';
import PlusMDSL from './specificTables/plusmdsl';
import PlusSLMD from './specificTables/plusslmd';
import Pf from './specificTables/pf';

function MonthlyDetailsTable({ data, month, list }) {

    const { HTupdatedFirst, HTplus5List, HTminus5List, HTplusMDSLList, HTminusMDSLList, HTtotalPowerFactorLessThan9List, HTsecond, HTthird, HTfourth, HTfifth, HTsixth } = data;
    const monthList = [HTupdatedFirst, HTsecond, HTthird, HTfourth, HTfifth, HTsixth].reverse();

    return (
        <>

            {
                (list === 'plus5' && <Plus5 data={data} list={HTplus5List[month - 1]} />)
                    ?
                    <Plus5 data={data} list={HTplus5List[month - 1]} />
                    :
                    (list === 'minus5' && <Minus5 data={data} list={HTminus5List[month - 1]} />)
                        ?
                        <Minus5 data={data} list={HTminus5List[month - 1]} />
                        :
                        (list === 'plusmdsl' && <PlusMDSL data={data} list={HTplusMDSLList[month]} />)
                            ?
                            <PlusMDSL data={data} list={HTplusMDSLList[month]} />
                            :
                            (list === 'plusslmd' && <PlusSLMD data={data} list={HTminusMDSLList[month]} />)
                                ?
                                <PlusSLMD data={data} list={HTminusMDSLList[month]} />
                                :
                                (list === 'total' && <Total data={data} list={monthList[month]} />)
                                    ?
                                    <Total data={data} list={monthList[month]} />
                                    :
                                    (list === 'pf' && <Pf data={data} list={HTtotalPowerFactorLessThan9List[month]} />)
                                        ?
                                        <Pf data={data} list={HTtotalPowerFactorLessThan9List[month]} />
                                        :
                                        null
            }
        </>
    )
}

export default MonthlyDetailsTable;
