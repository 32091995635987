


function ConnectionData({ x }) {


    return (
        <>

            <table id="monthlyDetailsTable" className="table table-hover table-bordered table-secondary table-striped border border-secondary mt-3 pt-5">

                <tbody>

                    <tr>
                        <td className='fs-4'>IVRS Number</td>
                        <td className="fs-5">
                            {typeof (x.consumerNo) === 'number' ? parseInt((x.consumerNo)) : (x.consumerNo)}
                        </td>
                    </tr>
                    {
                        x.latitude ?
                            <tr>
                                <td className='fs-4'>Location</td>
                                {/* <td><div className="vl"></div></td> */}
                                <td className="fs-5">
                                    <a href={`https://maps.google.com/?q=${x.latitude},${x.longitude}`} rel='noreferrer' target='_blank'>Map Link</a>
                                </td>
                            </tr> : null
                    }

                    <tr>
                        <td className='fs-4'>Advance Payment</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.advancePayment) === 'number' ? parseInt((x.advancePayment)) : (x.advancePayment)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Arrears</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.arrears) === 'number' ? parseInt((x.arrears)) : (x.arrears)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Bill-Cycle</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.billCycle) === 'number' ? parseInt((x.billCycle)) : (x.billCycle)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Bill-Date</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.billDate) === 'number' ? parseInt((x.billDate)) : (x.billDate)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Bill-Month-Year</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.billMonthYear) === 'number' ? parseInt((x.billMonthYear)) : (x.billMonthYear)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Circle</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.circle) === 'number' ? parseInt((x.circle)) : (x.circle)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Connection Date</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.connectionDate) === 'number' ? parseInt((x.connectionDate)) : (x.connectionDate)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Connection Type</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.connectionType) === 'number' ? parseInt((x.connectionType)) : (x.connectionType)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Cons-Address</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.consAddress) === 'number' ? parseInt((x.consAddress)) : (x.consAddress)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Consumer Name</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.consumerName) === 'number' ? parseInt((x.consumerName)) : (x.consumerName)}
                        </td>
                    </tr>
                    {/* <tr>
                        <td className='fs-4'>Consumer No</td>
                        <td><div className="vl"></div></td>
                        <td className="fs-5">
                            {typeof (x.consumerNo) === 'number'?parseInt((x.consumerNo)):(x.consumerNo)}
                        </td>
                    </tr> */}
                    <tr>
                        <td className='fs-4'>Contract Load</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.contractLoad) === 'number' ? parseInt((x.contractLoad)) : (x.contractLoad)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Contract Month Bill</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.contractMonthBill) === 'number' ? parseInt((x.contractMonthBill)) : (x.contractMonthBill)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Department Code</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.deptCode) === 'number' ? parseInt((x.deptCode)) : (x.deptCode)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Department Name</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.deptName) === 'number' ? parseInt((x.deptName)) : (x.deptName)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Dispute Arrears</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.disputeArrears) === 'number' ? parseInt((x.disputeArrears)) : (x.disputeArrears)}
                        </td>
                    </tr>

                    <tr>
                        <td className='fs-4'>Dispute-Arrears-Non-r15</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.disputeArrearsNonr15) === 'number' ? parseInt((x.disputeArrearsNonr15)) : (x.disputeArrearsNonr15)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Division Name</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.divisionName) === 'number' ? parseInt((x.divisionName)) : (x.divisionName)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Electricity Duty</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.electricityDuty) === 'number' ? parseInt((x.electricityDuty)) : (x.electricityDuty)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Email</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.email) === 'number' ? parseInt((x.email)) : (x.email)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Energy Charges</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.energyCharges) === 'number' ? parseInt((x.energyCharges)) : (x.energyCharges)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Energy Charges Rfifteen</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.energyChargesRfifteen) === 'number' ? parseInt((x.energyChargesRfifteen)) : (x.energyChargesRfifteen)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>FCA-Charges</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.fcaCharges) === 'number' ? parseInt((x.fcaCharges)) : (x.fcaCharges)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>GMC-Cons</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.gmcCons) === 'number' ? parseInt((x.gmcCons)) : (x.gmcCons)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Government-Con-Cat </td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.govtConCat) === 'number' ? parseInt((x.govtConCat)) : (x.govtConCat)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Government Flag</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.govtFlag) === 'number' ? parseInt((x.govtFlag)) : (x.govtFlag)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>KVAH-End</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.kvahEnd) === 'number' ? parseInt((x.kvahEnd)) : (x.kvahEnd)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>KWH-End</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.kwhEnd) === 'number' ? parseInt((x.kwhEnd)) : (x.kwhEnd)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Kwh_Nor-End</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.kwhNorEnd) === 'number' ? parseInt((x.kwhNorEnd)) : (x.kwhNorEnd)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>KWH-Opeak-end</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.kwhOpeakEnd) === 'number' ? parseInt((x.kwhOpeakEnd)) : (x.kwhOpeakEnd)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>KWH-Peek-End</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.kwhPeekEnd) === 'number' ? parseInt((x.kwhPeekEnd)) : (x.kwhPeekEnd)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>LF-Avg</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.lfAvg) === 'number' ? parseInt((x.lfAvg)) : (x.lfAvg)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>MD-Billing-Demand</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.mdBillingDemand) === 'number' ? parseInt((x.mdBillingDemand)) : (x.mdBillingDemand)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>MD-Recorded</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.mdRecorded) === 'number' ? parseInt((x.mdRecorded)) : (x.mdRecorded)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Meter-Serial-Number</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.meterSerialNo) === 'number' ? parseInt((x.meterSerialNo)) : (x.meterSerialNo)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>MF</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.mf) === 'number' ? parseInt((x.mf)) : (x.mf)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Mobile</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.mobile) === 'number' ? parseInt((x.mobile)) : (x.mobile)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Net-Bill</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.netBill) === 'number' ? parseInt((x.netBill)) : (x.netBill)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Net-Bill With LPC</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.netBillWithLpc) === 'number' ? parseInt((x.netBillWithLpc)) : (x.netBillWithLpc)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Net-Max-Demand</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.netMaxDemand) === 'number' ? parseInt((x.netMaxDemand)) : (x.netMaxDemand)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Net Meter Balance Unit Adjustment</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.netMeterBalanceUnitAdjAmt) === 'number' ? parseInt((x.netMeterBalanceUnitAdjAmt)) : (x.netMeterBalanceUnitAdjAmt)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Normal Units</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.normalUnits) === 'number' ? parseInt((x.normalUnits)) : (x.normalUnits)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Off Peak Units</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.offpeakUnits) === 'number' ? parseInt((x.offpeakUnits)) : (x.offpeakUnits)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Online Date</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.onlineDate) === 'number' ? parseInt((x.onlineDate)) : (x.onlineDate)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Online Pay Rebate</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.onlinePayRebate) === 'number' ? parseInt((x.onlinePayRebate)) : (x.onlinePayRebate)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Other</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.other) === 'number' ? parseInt((x.other)) : (x.other)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Pan Card Number</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.panCardNo) === 'number' ? parseInt((x.panCardNo)) : (x.panCardNo)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Peak Units</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.peakUnits) === 'number' ? parseInt((x.peakUnits)) : (x.peakUnits)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>pf average</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.pfAvg) === 'number' ? parseFloat((x.pfAvg)) : x.pfAvg}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>pf Surcharge Total</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.pfSurchargeTotal) === 'number' ? parseInt((x.pfSurchargeTotal)) : (x.pfSurchargeTotal)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Prompt Payment</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.promptPayment) === 'number' ? parseInt((x.promptPayment)) : (x.promptPayment)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Purpose Name</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.purposeName) === 'number' ? parseInt((x.purposeName)) : (x.purposeName)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Read-Date</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.readDate) === 'number' ? parseInt((x.readDate)) : (x.readDate)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Read-Type</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.readType) === 'number' ? parseInt((x.readType)) : (x.readType)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Region</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.region) === 'number' ? parseInt((x.region)) : (x.region)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Registration-Refund</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.registrationRefund) === 'number' ? parseInt((x.registrationRefund)) : (x.registrationRefund)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Sd-Held</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.sdHeld) === 'number' ? parseInt((x.sdHeld)) : (x.sdHeld)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>SD-Int</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.sdInt) === 'number' ? parseInt((x.sdInt)) : (x.sdInt)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>SD-Refund Or Demand</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.sdRefundOrDemand) === 'number' ? parseInt((x.sdRefundOrDemand)) : (x.sdRefundOrDemand)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Sold Units</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.soldUnits) === 'number' ? parseInt((x.soldUnits)) : (x.soldUnits)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Subsidy</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.subsidy) === 'number' ? parseInt((x.subsidy)) : (x.subsidy)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Supply Vos</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.supplyVos) === 'number' ? parseInt((x.supplyVos)) : (x.supplyVos)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Surcharge</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.surcharge) === 'number' ? parseInt((x.surcharge)) : (x.surcharge)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Tariff Code</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.tariffSubCategoryCode) === 'number' ? parseInt((x.tariffSubCategoryCode)) : (x.tariffSubCategoryCode)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>TDS</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.tds) === 'number' ? parseInt((x.tds)) : (x.tds)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Temp-Demand</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.tempDemand) === 'number' ? parseInt((x.tempDemand)) : (x.tempDemand)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Tmm-Consump</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.tmmConsump) === 'number' ? parseInt((x.tmmConsump)) : (x.tmmConsump)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Tmm-Difference-Total</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.tmmDifferenceTotal) === 'number' ? parseInt((x.tmmDifferenceTotal)) : (x.tmmDifferenceTotal)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>TOD-Rebate-Total</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.todRebateTotal) === 'number' ? parseInt((x.todRebateTotal)) : (x.todRebateTotal)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Total-Units-Kvah</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.totalUnitsKvah) === 'number' ? parseInt((x.totalUnitsKvah)) : (x.totalUnitsKvah)}
                        </td>
                    </tr>
                    <tr>
                        <td className='fs-4'>Total-Units-KWAH</td>
                        {/* <td><div className="vl"></div></td> */}
                        <td className="fs-5">
                            {typeof (x.totalUnitsKwh) === 'number' ? parseInt((x.totalUnitsKwh)) : (x.totalUnitsKwh)}
                        </td>
                    </tr>

                </tbody >
            </table >

        </>
    )
}

export default ConnectionData;