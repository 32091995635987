import Tr from "./tr";

function CommercialDetails({ data }) {

    const { sequenceLT, LTPDCSecurityAmountDeposit, LTcurrentMonthTotalBill, LTtotalPDCList, LTtotalBill, LTtotalConsumption, LTtotalFixedCharge, LTzeroFixedCharge, LTzeroSecurityAmountDeposit, LTsurchargePayableOnDelay, LTtotalPowerFactorLessThan9List, LTpowerFactorLessThan9TotalWeldingCharge, first, second, third, fourth, fifth, sixth } = data;
    const buildConnectionsList = [first.length, second.length, third.length, fourth.length, fifth.length, sixth.length].reverse();
    return (
        <>
            <div className="container-fluid d-flex justify-content-center">
                <table id='analysis-table' className="table table-hover table-secondary table-bordered table-striped border border-secondary mt-3 pt-5">
                    <tbody>
                        <tr>
                            <td className='fs-4' colSpan={3}><h3 className='d-flex justify-content-center'><span className="">{sequenceLT[5]}</span></h3></td>
                        </tr>
                        <Tr sequence={sequenceLT} show='Connections' order={'one'} list={buildConnectionsList} division={1} data={buildConnectionsList[5]}>Total Billed Connections :</Tr>
                        <Tr sequence={sequenceLT} show='Cr Rs' order={'two'} list={LTtotalBill} division={10000000} data={LTtotalBill[5]}>Total Amount Due (Rs):</Tr>
                        <Tr sequence={sequenceLT} show='Cr Rs' order={'three'} list={LTcurrentMonthTotalBill} division={10000000} data={parseInt(LTcurrentMonthTotalBill[5])}>Current Month Bill (Rs):</Tr>
                        <Tr sequence={sequenceLT} show='lakh Rs' order={'kay'} list={LTtotalFixedCharge} division={100000} data={LTtotalFixedCharge[5]} >Fixed Charges (Rs):</Tr>
                        <Tr sequence={sequenceLT} show='Connections' order={'eight'} list={LTtotalPowerFactorLessThan9List} division={1} data={LTtotalPowerFactorLessThan9List[5].length} >Connections with PF {'<'} 0.8:</Tr>
                        <Tr sequence={sequenceLT} show='lakh Rs' order={'nine'} list={LTpowerFactorLessThan9TotalWeldingCharge} division={100000} data={parseInt(LTpowerFactorLessThan9TotalWeldingCharge[5])} >Total PF Surcharge (Rs):</Tr>
                        <Tr sequence={sequenceLT} show='Connections' order={'ten'} list={LTtotalPDCList} division={1} data={parseInt(LTtotalPDCList[5].length)} > PDC Connections:</Tr>
                        <Tr sequence={sequenceLT} show='lakh Rs' order={'eleven'} list={LTPDCSecurityAmountDeposit} division={100000} data={parseInt(LTPDCSecurityAmountDeposit[5])} >Total Security Deposit of PDC connections:</Tr>
                    </tbody>
                </table>
            </div>
        </>
    )
};

export default CommercialDetails;