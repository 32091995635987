import Tr from '../../../../components/table/tr';


export default function TableLT({ data }) {
    const { sequenceLT, LTplus5List, LTminus5List, LTtotalPowerFactorLessThan9List, LTtotalPDCList, LTzeroList, LTplusMDSLList, LTminusMDSLList, first, second, third, fourth, fifth, sixth, LTtotalLoad, LTtotalSanctionedLoad, LTtotalConsumption, LTtotalBill, LTcurrentMonthTotalBill, LTtotalFixedCharge, LTpowerFactorLessThan9TotalWeldingCharge, LTPDCSecurityAmountDeposit } = data;
    const buildConnectionsList = [first.length, second.length, third.length, fourth.length, fifth.length, sixth.length].reverse();
    return (
        <>
            <div className="d-flex justify-content-center">
                <table id='analysis-table' className="w-75 table table-hover table-secondary table-bordered table-striped border border-secondary pt-5">
                    <tbody>
                        <tr>
                            <td className='fs-5' colSpan={3}><h3 className='d-flex justify-content-center'><span className="">{data.sequenceLT[5]}</span></h3></td>
                        </tr>
                        <Tr sequence={sequenceLT} show={['Connections']} order={'a'} list={buildConnectionsList} secondList={''} division={1} data={buildConnectionsList[5]} >Total Billed Connections:</Tr>
                        <Tr sequence={sequenceLT} show={['lakh kWh']} order={'ac'} list={LTtotalConsumption} secondList={''} division={100000} data={LTtotalConsumption[5]}>Total Consumption (kWh):</Tr>
                        <Tr sequence={sequenceLT} show={['Cr Rs']} order={'r'} list={LTcurrentMonthTotalBill} secondList={''} division={10000000} data={parseInt(LTcurrentMonthTotalBill[5])}>Total Month Bill (Rs):</Tr>
                        <Tr sequence={sequenceLT} show={['Cr Rs']} order={'t'} list={LTtotalBill} secondList={''} division={10000000} data={LTtotalBill[5]}>Total Amount Due (Rs):</Tr>
                        <tr></tr>
                        <tr>
                            <td className='fs-5' colSpan={3}><h3 className='d-flex justify-content-center'><span className="">Load Analysis</span></h3></td>
                        </tr>
                        <Tr sequence={sequenceLT} show={['Maximum Demand (MW)', 'Sanctioned Load (MW)']} order={'ca'} list={LTtotalLoad} secondList={LTtotalSanctionedLoad} division={1000} data={LTtotalLoad[5]} >Total Load (kW):</Tr>
                        <Tr sequence={sequenceLT} show={['lakh Rs']} order={'er'} list={LTtotalFixedCharge} secondList={''} division={100000} data={LTtotalFixedCharge[5]} >Fixed Charges (Rs):</Tr>
                        <Tr sequence={sequenceLT} show={['Connections']} order={'f'} list={LTplusMDSLList} secondList={''} division={1} data={LTplusMDSLList[5].length} >Connections with MD-SL {'>'} 20%:</Tr>
                        <Tr sequence={sequenceLT} show={['Connections']} order={'c'} list={LTminusMDSLList} secondList={''} division={1} data={LTminusMDSLList[5].length} >Connections with SL-MD {'>'} 10%:</Tr>
                        <tr></tr>
                        <tr>
                            <td className='fs-5' colSpan={3}><h3 className='d-flex justify-content-center'><span className="">PF Analysis</span></h3></td>
                        </tr>

                        <Tr sequence={sequenceLT} show={['Connections']} order={'v'} list={LTtotalPowerFactorLessThan9List} secondList={''} division={1} data={LTtotalPowerFactorLessThan9List[5].length} >Connections with PF {'<'} 0.8:</Tr>
                        <Tr sequence={sequenceLT} show={['lakh Rs']} order={'re'} list={LTpowerFactorLessThan9TotalWeldingCharge} secondList={''} division={100000} data={parseInt(LTpowerFactorLessThan9TotalWeldingCharge[5])} >Total PF Surcharge (Rs):</Tr>
                        <tr></tr>
                        <tr>
                            <td className='fs-5' colSpan={3}><h3 className='d-flex justify-content-center'><span className="">Consumption Analysis</span></h3></td>
                        </tr>
                        <Tr sequence={sequenceLT} show={['Connections']} order={'h'} list={LTzeroList} secondList={''} division={1} data={LTzeroList[5].length} >Connections with Zero Consumption:</Tr>
                        <Tr sequence={sequenceLT} show={['Connections']} order={'n'} list={LTplus5List} secondList={''} division={1} data={LTplus5List[4].length} >Connections with Consumption +5%:</Tr>
                        <Tr sequence={sequenceLT} show={['Connections']} order={'m'} list={LTminus5List} secondList={''} division={1} data={LTminus5List[4].length} >Connections with Consumption -5%:</Tr>
                        <tr></tr>
                        <tr>
                            <td className='fs-5' colSpan={3}><h3 className='d-flex justify-content-center'><span className="">PDC Analysis</span></h3></td>
                        </tr>
                        <Tr sequence={sequenceLT} show={['Connections']} order={'b'} list={LTtotalPDCList} secondList={''} division={1} data={parseInt(LTtotalPDCList[5].length)} >PDC Connections:</Tr>
                        <Tr sequence={sequenceLT} show={['lakh Rs']} order={'tr'} list={LTPDCSecurityAmountDeposit} secondList={''} division={100000} data={parseInt(LTPDCSecurityAmountDeposit[5])} >Total Security Deposit of PDC connections:</Tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}