import Tr from './tr';

function DetailsAnalysisLT({ data }) {
    const { sequenceLT, LTplus5List, LTminus5List, LTtotalPowerFactorLessThan9List, LTtotalPDCList, LTzeroList, LTplusMDSLList, LTminusMDSLList, first, second, third, fourth, fifth, sixth, LTtotalLoad, LTtotalSanctionedLoad, LTtotalConsumption } = data;
    const buildConnectionsList = [first.length, second.length, third.length, fourth.length, fifth.length, sixth.length].reverse();
    return (
        <>
            <div className="container-fluid d-flex justify-content-center">
                <table id='analysis-table' className="table table-hover table-secondary table-bordered table-striped border border-secondary pt-5">
                    <tbody>
                        <tr>
                            <td className='fs-4' colSpan={3}><h3 className='d-flex justify-content-center'><span className="">{sequenceLT[5]}</span></h3></td>
                        </tr>
                        <Tr sequence={sequenceLT} show={['Connections']} order={'klm'} list={buildConnectionsList} secondList={''} division={1} data={buildConnectionsList[5]} >Total Billed Connections:</Tr>
                        <Tr sequence={sequenceLT} show={['lakh kWh']} order={'zen'} list={LTtotalConsumption} secondList={''} division={100000} data={LTtotalConsumption[5]}>Total Consumption (kWh):</Tr>
                        <Tr sequence={sequenceLT} show={['Maximum Demand (MW)', 'Sanctioned Load (MW)']} order={'zvp'} list={LTtotalLoad} secondList={LTtotalSanctionedLoad} division={1000} data={LTtotalLoad[5]} >Total Load (kW):</Tr>
                        <Tr sequence={sequenceLT} show={['Connections']} order={'three'} list={LTplusMDSLList} secondList={''} division={1} data={LTplusMDSLList[5].length} >Connections with MD-SL {'>'} 20%:</Tr>
                        <Tr sequence={sequenceLT} show={['Connections']} order={'four'} list={LTminusMDSLList} secondList={''} division={1} data={LTminusMDSLList[5].length} >Connections with SL-MD {'>'} 10%:</Tr>
                        <Tr sequence={sequenceLT} show={['Connections']} order={'k'} list={LTtotalPowerFactorLessThan9List} secondList={''} division={1} data={LTtotalPowerFactorLessThan9List[5].length} >Connections with PF {'<'} 0.8:</Tr>
                        <Tr sequence={sequenceLT} show={['Connections']} order={'tn'} list={LTtotalPDCList} secondList={''} division={1} data={parseInt(LTtotalPDCList[5].length)} > PDC Connections:</Tr>
                        <Tr sequence={sequenceLT} show={['Connections']} order={'five'} list={LTzeroList} secondList={''} division={1} data={LTzeroList[5].length} >Connections with Zero Consumption:</Tr>
                        <Tr sequence={sequenceLT} show={['Connections']} order={'one'} list={LTplus5List} secondList={''} division={1} data={LTplus5List[4].length} >Connections with Consumption +5%:</Tr>
                        <Tr sequence={sequenceLT} show={['Connections']} order={'two'} list={LTminus5List} secondList={''} division={1} data={LTminus5List[4].length} >Connections with Consumption -5%:</Tr>                    </tbody>
                </table>
            </div>
        </>
    )
}

export default DetailsAnalysisLT;