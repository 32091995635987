import DetailsAnalysisLT from './DetailsAnalysis/Table/DetailsAnalysisLT';
import DetailsAnalysisHT from './DetailsAnalysis/Table/DetailsAnalysisHT';
import DetailsChartLT from './DetailsAnalysis/DetailsChart/DetailsChartLT';
import DetailsChartHT from './DetailsAnalysis/DetailsChart/DetailsChartHT';
import BeforeLogin from './../../BeforeLogin/BeforeLogin';
import Banner from '../../../banner/banner';

function AnalysisPage({ data, existingUser }) {

    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const d = new Date();
    const date = ' ' + month[d.getMonth()] + ' ' + d.getFullYear();

    return (
        <>
            {
                existingUser ?
                    <>
                        <section className="container" id='login-section'>

                            {/* <Banner /> */}
                            {/* <div className="d-flex justify-content-center mt-2">
                                <h3><span className="">{date}</span></h3>
                            </div> */}


                            <div className="container py-5 h-100">

                                <h1 className='d-flex justify-content-center mb-3'>LT Analysis</h1>
                                <div className="d-flex justify-content-center mt-4">
                                    <DetailsAnalysisLT data={data} />
                                </div>
                                {/* <DetailsChartLT data={data} /> */}

                                <h1 className='d-flex justify-content-center mt-5 my-2'>HT Analysis</h1>
                                <div className="d-flex justify-content-center mt-4">
                                    <DetailsAnalysisHT data={data} />
                                </div>
                                {/* <DetailsChartHT data={data} /> */}

                            </div>
                        </section>
                    </>
                    :
                    <BeforeLogin />
            }
        </>
    )
}

export default AnalysisPage;