import CommercialDetailsLT from './CommercialDetails/table/CommercialDetailsLT';
import CommercialDetailsHT from './CommercialDetails/table//CommercialDetailsHT';
import BeforeLogin from './../../BeforeLogin/BeforeLogin';



function Commercial({ data, existingUser }) {

    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const d = new Date();
    const date = ' ' + month[d.getMonth()] + ' ' + d.getFullYear();

    return (
        <>
            {
                existingUser ?
                    <>
                        <section className="container" id='login-section'>

                            {/* <div className="d-flex justify-content-center mt-2">
                                <h3><span className="">{date}</span></h3>
                            </div> */}

                            <div className="container py-5 h-100">
                                <h1 className='d-flex justify-content-center mb-2'>LT Commercial Details</h1>
                                <CommercialDetailsLT data={data} />
                                <h1 className='d-flex justify-content-center mt-4 mb-2'>HT Commercial Details</h1>
                                <CommercialDetailsHT data={data} />
                            </div>
                        </section>
                    </>
                    :
                    <BeforeLogin />
            }
        </>
    )
}

export default Commercial;