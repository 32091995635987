import Graph from "../../graph";

function Tr({ children, data, order, list, division, sequence,show }) {
    return (
        <>
            <tr>
                <td className='fs-4'>{children}</td>
                {/* <td><div className="vl"></div></td> */}
                <td className='fs-4'>
                    <span className="d-flex justify-content-end">
                    {data.toLocaleString("en-IN")}
                </span>
                </td>
                <td>
                    <button className="btn btn-secondary text-white rounded  collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#' + order} aria-expanded="false" aria-controls={order}>
                        History
                    </button>
                </td>
            </tr >
            <tr>
                <td colSpan='3'>
                    <div id={order} className="accordion-collapse collapse">
                        <div className="accordion-body">
                            <Graph sequence={sequence} list={list} division={division}show={show} />
                        </div>
                    </div>
                </td>
            </tr>
        </>
    );
}

export default Tr;

