import { doc, getDoc } from "firebase/firestore";
import { db } from './FirebaseConfig';
import locationData from "./data/geoLocation.json";


function sortDataSequence(num = 0) {
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    var sequence = [];
    const currentMonth = new Date().getMonth() - num;
    const currentYear = new Date().getFullYear();
    if (currentMonth < 5) {

        for (let i = currentMonth; i >= 0; i--) {
            sequence.push(months[i] + '-' + JSON.stringify(currentYear));
        };
        const startYear = currentYear - 1;
        const startMonth = 7 + currentMonth;
        for (let i = 11; i >= startMonth; i--) {
            sequence.push(months[i] + '-' + JSON.stringify(startYear));
        }
    } else {
        sequence.push(months[currentMonth] + '-' + JSON.stringify(currentYear));
        sequence.push(months[currentMonth - 1] + '-' + JSON.stringify(currentYear));
        sequence.push(months[currentMonth - 2] + '-' + JSON.stringify(currentYear));
        sequence.push(months[currentMonth - 3] + '-' + JSON.stringify(currentYear));
        sequence.push(months[currentMonth - 4] + '-' + JSON.stringify(currentYear));
        sequence.push(months[currentMonth - 5] + '-' + JSON.stringify(currentYear));
    };
    return sequence;
};


//*: LT Data Handling
async function getSixMonthsLTData() {
    try {
        let data = {};
        const test = await getSpecificMonthLTData(sortDataSequence(0)[0]);
        const monthStatusLT = test?.length > 500 ? 0 : 1;
        const dataSequence = sortDataSequence(monthStatusLT);
        let firstMonthData = await getSpecificMonthLTData(dataSequence[0]);
        let secondMonthData = await getSpecificMonthLTData(dataSequence[1]);
        let thirdMonthData = await getSpecificMonthLTData(dataSequence[2]);
        let fourthMonthData = await getSpecificMonthLTData(dataSequence[3]);
        let fifthMonthData = await getSpecificMonthLTData(dataSequence[4]);
        let sixthMonthData = await getSpecificMonthLTData(dataSequence[5]);
        data.first = firstMonthData;
        data.second = secondMonthData;
        data.third = thirdMonthData;
        data.fourth = fourthMonthData;
        data.fifth = fifthMonthData;
        data.sixth = sixthMonthData;
        return data;
    } catch (error) {
        console.error('Error:', error.message);
    }
};


async function getSpecificMonthLTData(month_year) {
    try {

        const docRef1 = doc(db, month_year, "firstPart-LT");
        const docRef2 = doc(db, month_year, "secondPart-LT");
        const docRef3 = doc(db, month_year, "thirdPart-LT");
        const docRef4 = doc(db, month_year, "fourthPart-LT");
        const docSnap1 = await getDoc(docRef1);
        const docSnap2 = await getDoc(docRef2);
        const docSnap3 = await getDoc(docRef3);
        const docSnap4 = await getDoc(docRef4);

        if (docSnap1.exists() && docSnap2.exists() && docSnap3.exists() && docSnap4.exists()) {
            const result = [];
            result.push(...docSnap1.data().allowedData1);
            result.push(...docSnap2.data().allowedData2);
            result.push(...docSnap3.data().allowedData3);
            result.push(...docSnap4.data().allowedData4);
            return result;
        } else {
            console.log("No such document!");
        }
    } catch (e) {
        console.error('Error:', e.message);
    }
};

//*: HT Data Handling

async function getSpecificMonthHTData(month_year) {
    try {
        const docRef = doc(db, month_year, "Full-HT-Data");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const result = [];
            result.push(...docSnap.data().data);
            return result;
        } else {
            console.log("No such document!");
        }
    } catch (e) {
        console.error('Error:', e.message);
        return null;
    }
};

async function getSixMonthsHTData() {
    try {
        let data = {};
        const test = await getSpecificMonthHTData(sortDataSequence(0)[0])
        const monthStatusHT = test?.length > 30 ? 0 : 1;
        const dataSequence = sortDataSequence(monthStatusHT);
        let firstMonthData = await getSpecificMonthHTData(dataSequence[0]);
        let secondMonthData = await getSpecificMonthHTData(dataSequence[1]);
        let thirdMonthData = await getSpecificMonthHTData(dataSequence[2]);
        let fourthMonthData = await getSpecificMonthHTData(dataSequence[3]);
        let fifthMonthData = await getSpecificMonthHTData(dataSequence[4]);
        let sixthMonthData = await getSpecificMonthHTData(dataSequence[5]);
        data.HTfirst = firstMonthData;
        data.HTsecond = secondMonthData;
        data.HTthird = thirdMonthData;
        data.HTfourth = fourthMonthData;
        data.HTfifth = fifthMonthData;
        data.HTsixth = sixthMonthData;
        return data;
    } catch (error) {
        console.error('Error:', error.message);
    }
};

//*: Full Sorting of data
async function sortedData() {
    try {
        //*: HT
        const dataHT = await getSixMonthsHTData();
        const { HTfirst, HTsecond, HTthird, HTfourth, HTfifth, HTsixth } = dataHT;
        let
            HTupdatedFirst = [],
            HTtotalLoad = [0, 0, 0, 0, 0, 0],
            HTtotalContractDemand = [0, 0, 0, 0, 0, 0],
            HTtotalConsumption = [0, 0, 0, 0, 0, 0],
            HTtotalBill = [0, 0, 0, 0, 0, 0],
            HTtotalFixedCharges = [0, 0, 0, 0, 0, 0],
            HTplusMDSLList = [[], [], [], [], [], []],
            HTminusMDSLList = [[], [], [], [], [], []],
            HTplus5List = [[], [], [], [], []],
            HTminus5List = [[], [], [], [], []],
            HTtotalPowerFactorLessThan9List = [[], [], [], [], [], []],
            HTpowerFactorLessThan9TotalWeldingCharge = [0, 0, 0, 0, 0, 0],
            HTsurchargePayableOnDelay = [0, 0, 0, 0, 0, 0],
            HTcurrentMonthTotalBill = [0, 0, 0, 0, 0, 0];


        for (let i = 0; i < HTfirst.length; i++) {

            //*: HT UpdateFirst Adding Location to first
            const currentMonthAccountDataHT = HTfirst[i];
            const t = locationData.find((x) => {
                return (x.IVRS === currentMonthAccountDataHT.consumerNo);
            });
            if (t) {
                currentMonthAccountDataHT.latitude = t.latitude;
                currentMonthAccountDataHT.longitude = t.longitude;
            }
            else {
                currentMonthAccountDataHT.latitude = '';
                currentMonthAccountDataHT.longitude = '';
            }
            HTupdatedFirst.push(currentMonthAccountDataHT);

            //*: Total Load
            HTtotalLoad[5] += parseInt(HTfirst[i].netMaxDemand);
            HTtotalLoad[4] += parseInt(HTsecond[i].netMaxDemand);
            HTtotalLoad[3] += parseInt(HTthird[i].netMaxDemand);
            HTtotalLoad[2] += parseInt(HTfourth[i].netMaxDemand);
            HTtotalLoad[1] += parseInt(HTfifth[i].netMaxDemand);
            HTtotalLoad[0] += parseInt(HTsixth[i].netMaxDemand);

            //*:Total Contract Demand
            HTtotalContractDemand[5] += parseInt(HTfirst[i].contractLoad);
            HTtotalContractDemand[4] += parseInt(HTsecond[i].contractLoad);
            HTtotalContractDemand[3] += parseInt(HTthird[i].contractLoad);
            HTtotalContractDemand[2] += parseInt(HTfourth[i].contractLoad);
            HTtotalContractDemand[1] += parseInt(HTfifth[i].contractLoad);
            HTtotalContractDemand[0] += parseInt(HTsixth[i].contractLoad);

            //*: Total Energy
            HTtotalConsumption[5] += parseInt(HTfirst[i].totalUnitsKwh);
            HTtotalConsumption[4] += parseInt(HTsecond[i].totalUnitsKwh);
            HTtotalConsumption[3] += parseInt(HTthird[i].totalUnitsKwh);
            HTtotalConsumption[2] += parseInt(HTfourth[i].totalUnitsKwh);
            HTtotalConsumption[1] += parseInt(HTfifth[i].totalUnitsKwh);
            HTtotalConsumption[0] += parseInt(HTsixth[i].totalUnitsKwh);

            //*: Total Bill
            HTtotalBill[5] += parseInt(HTfirst[i].netBill);
            HTtotalBill[4] += parseInt(HTsecond[i].netBill);
            HTtotalBill[3] += parseInt(HTthird[i].netBill);
            HTtotalBill[2] += parseInt(HTfourth[i].netBill);
            HTtotalBill[1] += parseInt(HTfifth[i].netBill);
            HTtotalBill[0] += parseInt(HTsixth[i].netBill);

            //*: Total Fixed Charge
            HTtotalFixedCharges[5] += parseInt(HTfirst[i].fixedCharges);
            HTtotalFixedCharges[4] += parseInt(HTsecond[i].fixedCharges);
            HTtotalFixedCharges[3] += parseInt(HTthird[i].fixedCharges);
            HTtotalFixedCharges[2] += parseInt(HTfourth[i].fixedCharges);
            HTtotalFixedCharges[1] += parseInt(HTfifth[i].fixedCharges);
            HTtotalFixedCharges[0] += parseInt(HTsixth[i].fixedCharges);

            //*: Total Power Factor Less than 0.9 Connections
            if (0 < parseFloat(HTupdatedFirst[i].pfAvg) && parseFloat(HTupdatedFirst[i].pfAvg) < 0.9) {
                HTtotalPowerFactorLessThan9List[5].push(HTupdatedFirst[i]);
                HTpowerFactorLessThan9TotalWeldingCharge[5] += (HTupdatedFirst[i].pfSurchargeTotal);
            };
            if (0 < parseFloat(HTsecond[i].pfAvg) && parseFloat(HTsecond[i].pfAvg) < 0.9) {
                HTtotalPowerFactorLessThan9List[4].push(HTsecond[i]);
                HTpowerFactorLessThan9TotalWeldingCharge[4] += (HTsecond[i].pfSurchargeTotal);
            };
            if (0 < parseFloat(HTthird[i].pfAvg) && parseFloat(HTthird[i].pfAvg) < 0.9) {
                HTtotalPowerFactorLessThan9List[3].push(HTthird[i]);
                HTpowerFactorLessThan9TotalWeldingCharge[3] += (HTthird[i].pfSurchargeTotal);
            };
            if (0 < parseFloat(HTfourth[i].pfAvg) && parseFloat(HTfourth[i].pfAvg) < 0.9) {
                HTtotalPowerFactorLessThan9List[2].push(HTfourth[i]);
                HTpowerFactorLessThan9TotalWeldingCharge[2] += (HTfourth[i].pfSurchargeTotal);
            };
            if (0 < parseFloat(HTfifth[i].pfAvg) && parseFloat(HTfifth[i].pfAvg) < 0.9) {
                HTtotalPowerFactorLessThan9List[1].push(HTfifth[i]);
                HTpowerFactorLessThan9TotalWeldingCharge[1] += (HTfifth[i].pfSurchargeTotal);
            };
            if (0 < parseFloat(HTsixth[i].pfAvg) && parseFloat(HTsixth[i].pfAvg) < 0.9) {
                HTtotalPowerFactorLessThan9List[0].push(HTsixth[i]);
                HTpowerFactorLessThan9TotalWeldingCharge[0] += (HTsixth[i].pfSurchargeTotal);
            };


            //*: Surcharge Payable On Delay
            HTsurchargePayableOnDelay[5] += (HTfirst[i].surcharge);
            HTsurchargePayableOnDelay[4] += (HTsecond[i].surcharge);
            HTsurchargePayableOnDelay[3] += (HTthird[i].surcharge);
            HTsurchargePayableOnDelay[2] += (HTfourth[i].surcharge);
            HTsurchargePayableOnDelay[1] += (HTfifth[i].surcharge);
            HTsurchargePayableOnDelay[0] += (HTsixth[i].surcharge);


            //*: Total MDSL Analysis List
            function htMDSL(list, plusList, minusList) {
                let currentMaxDemand = parseInt(list[i].netMaxDemand);
                let currentSanctionedLoad = parseInt(list[i].contractLoad);;

                if ((((currentMaxDemand - currentSanctionedLoad) / currentSanctionedLoad * 100) > 20)) {
                    plusList.push(list[i]);
                } else if ((((currentSanctionedLoad - currentMaxDemand) / currentSanctionedLoad * 100) > 10)) {
                    minusList.push(list[i]);
                };
            };
            htMDSL(HTupdatedFirst, HTplusMDSLList[5], HTminusMDSLList[5]);
            htMDSL(HTsecond, HTplusMDSLList[4], HTminusMDSLList[4]);
            htMDSL(HTthird, HTplusMDSLList[3], HTminusMDSLList[3]);
            htMDSL(HTfourth, HTplusMDSLList[2], HTminusMDSLList[2]);
            htMDSL(HTfifth, HTplusMDSLList[1], HTminusMDSLList[1]);
            htMDSL(HTsixth, HTplusMDSLList[0], HTminusMDSLList[0]);


            //*: Total Monthly currentMonthBill
            HTcurrentMonthTotalBill[5] += parseInt(HTupdatedFirst[i].currentMonthBill);
            HTcurrentMonthTotalBill[4] += parseInt(HTsecond[i].currentMonthBill);
            HTcurrentMonthTotalBill[3] += parseInt(HTthird[i].currentMonthBill);
            HTcurrentMonthTotalBill[2] += parseInt(HTfourth[i].currentMonthBill);
            HTcurrentMonthTotalBill[1] += parseInt(HTfifth[i].currentMonthBill);
            HTcurrentMonthTotalBill[0] += parseInt(HTsixth[i].currentMonthBill);

            //*: Total consumption variation with 5 difference List
            function htFiveVariation(thisMonthList, previousMonthList, plusList, minusList) {
                const thisMonthAccountDataHT = thisMonthList.find((x) => {
                    return x.consumerNo === previousMonthList[i].consumerNo;
                });
                const previousMonthAccountDataHT = previousMonthList[i];
                if (thisMonthAccountDataHT) {
                    thisMonthAccountDataHT.previousMonthConsumption = previousMonthAccountDataHT.totalUnitsKwh;
                    let percentageVariation = ((parseFloat(previousMonthAccountDataHT.totalUnitsKwh) - parseFloat(thisMonthAccountDataHT.totalUnitsKwh)) / parseFloat(previousMonthAccountDataHT.totalUnitsKwh)) * 100;
                    thisMonthAccountDataHT.percentageVariation = -parseInt(percentageVariation);


                    if ((parseInt(thisMonthAccountDataHT.totalUnitsKwh) > parseInt(previousMonthAccountDataHT.totalUnitsKwh)) && parseInt(previousMonthAccountDataHT.totalUnitsKwh) !== 0) {
                        const result = ((parseFloat(thisMonthAccountDataHT.totalUnitsKwh) - parseFloat(previousMonthAccountDataHT.totalUnitsKwh)) / parseFloat(previousMonthAccountDataHT.totalUnitsKwh)) * 100;
                        if (result >= 5) {
                            plusList.push(thisMonthAccountDataHT);
                        }
                    }
                    else if (!parseInt(previousMonthAccountDataHT.totalUnitsKwh) && parseInt(thisMonthAccountDataHT.totalUnitsKwh)) {
                        plusList.push(thisMonthAccountDataHT);
                    }
                    else if (parseFloat(thisMonthAccountDataHT.totalUnitsKwh) && parseFloat(previousMonthAccountDataHT.totalUnitsKwh) && parseFloat(thisMonthAccountDataHT.totalUnitsKwh) < parseFloat(previousMonthAccountDataHT.totalUnitsKwh)) {
                        const result = ((parseFloat(previousMonthAccountDataHT.totalUnitsKwh) - parseFloat(thisMonthAccountDataHT.totalUnitsKwh)) / parseFloat(previousMonthAccountDataHT.totalUnitsKwh)) * 100;
                        if ((result) >= 5) {
                            minusList.push(thisMonthAccountDataHT);
                        }
                    } else if (parseFloat(previousMonthAccountDataHT.totalUnitsKwh) && !parseFloat(thisMonthAccountDataHT.totalUnitsKwh)) {
                        minusList.push(thisMonthAccountDataHT);
                    }
                }
            };

            htFiveVariation(HTfirst, HTsecond, HTplus5List[4], HTminus5List[4]);
            htFiveVariation(HTsecond, HTthird, HTplus5List[3], HTminus5List[3]);
            htFiveVariation(HTthird, HTfourth, HTplus5List[2], HTminus5List[2]);
            htFiveVariation(HTfourth, HTfifth, HTplus5List[1], HTminus5List[1]);
            htFiveVariation(HTfifth, HTsixth, HTplus5List[0], HTminus5List[0]);


        };


        //!: <-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------->

        //*: LT
        const data = await getSixMonthsLTData();
        let { first, second, third, fourth, fifth, sixth } = data;

        first = first.filter((x) => {
            return !(x.distributionCenter1 === 'Berasia')
        });
        second = second.filter((x) => {
            return !(x.distributionCenter1 === 'Berasia')
        });
        third = third.filter((x) => {
            return !(x.distributionCenter1 === 'Berasia')
        });
        fourth = fourth.filter((x) => {
            return !(x.distributionCenter1 === 'Berasia')
        });
        fifth = fifth.filter((x) => {
            return !(x.distributionCenter1 === 'Berasia')
        });
        sixth = sixth.filter((x) => {
            return !(x.distributionCenter1 === 'Berasia')
        });

        function update(list, previousList) {

            let updatedList = [];
            for (let i = 0; i < list.length; i++) {

                const t = locationData.find((x) => {
                    return (x.IVRS === list[i].accountId);
                });

                const s = previousList ? previousList.find((x) => {
                    return (x.accountId === list[i].accountId);
                }) : 0;

                let o = list.find((x) => {
                    return (x.accountId === list[i].accountId);
                });

                if (s) {
                    o.previousMonthConsumption = parseInt(s.totalConsumption);
                    if (parseInt(s.totalConsumption)) {
                        o.percentageVariation = parseInt(((parseInt(o.totalConsumption) - parseInt(s.totalConsumption)) / parseInt(s.totalConsumption)) * 100);
                    } else if (!parseInt(s.totalConsumption) && parseInt(o.totalConsumption)) {
                        o.percentageVariation = parseInt(o.totalConsumption);
                    }
                } else {
                    o.previousMonthConsumption = '';
                    o.percentageVariation = 'NaN';
                }

                if (t) {
                    o.latitude = t.latitude;
                    o.longitude = t.longitude;
                }
                else {
                    o.latitude = '';
                    o.longitude = '';
                }
                updatedList.push(o);
            }
            return updatedList;
        }



        first = update(first, second);
        second = update(second, third);
        third = update(third, third);
        fourth = update(fourth, third);
        fifth = update(fifth, third);
        sixth = update(sixth);

        let LTtotalLoad = [0, 0, 0, 0, 0, 0],
            LTtotalSanctionedLoad = [0, 0, 0, 0, 0, 0],
            LTtotalConsumption = [0, 0, 0, 0, 0, 0],
            LTtotalBill = [0, 0, 0, 0, 0, 0],
            LTtotalFixedCharge = [0, 0, 0, 0, 0, 0],
            LTzeroBillList = [[], [], [], [], [], []],
            LTplusMDSLList = [[], [], [], [], [], []],
            LTminusMDSLList = [[], [], [], [], [], []],
            LTplus5List = [[], [], [], [], []],
            LTminus5List = [[], [], [], [], []],
            LTtotalPowerFactorLessThan9List = [[], [], [], [], [], []],
            LTpowerFactorLessThan9TotalWeldingCharge = [0, 0, 0, 0, 0, 0],
            LTtotalPDCList = [[], [], [], [], [], []],
            LTzeroList = [[], [], [], [], [], []],
            LTzeroFixedCharge = [0, 0, 0, 0, 0, 0],
            LTzeroSecurityAmountDeposit = [0, 0, 0, 0, 0, 0],
            LTcurrentMonthTotalBill = [0, 0, 0, 0, 0, 0],
            LTPDCSecurityAmountDeposit = [0, 0, 0, 0, 0, 0],
            LTsurchargePayableOnDelay = [0, 0, 0, 0, 0, 0];



        //*: Total MDSL Analysis List
        function mdsl(list, plusList, minusList, i) {
            let currentMaxDemand = parseInt(list[i].maxDemand);
            let currentSanctionedLoad = parseInt(list[i].sanctionLoad.slice(0, -2));

            if ((((currentMaxDemand - currentSanctionedLoad) / currentSanctionedLoad * 100) > 20)) {
                plusList.push(list[i]);
            } else if ((((currentSanctionedLoad - currentMaxDemand) / currentSanctionedLoad * 100) > 10)) {
                minusList.push(list[i]);
            };
        };

        //*: Total consumption variation with 5 difference List
        function fiveVariation(thisMonthList, previousMonthList, plusList, minusList, i) {
            const thisMonthAccountData = thisMonthList.find((x) => {
                return x.accountId === previousMonthList[i].accountId;
            })
            const previousMonthAccountData = previousMonthList[i];
            if (thisMonthAccountData) {
                if ((parseInt(thisMonthAccountData.totalConsumption) > parseInt(previousMonthAccountData.totalConsumption)) && parseInt(previousMonthAccountData.totalConsumption) !== 0) {
                    const result = ((parseFloat(thisMonthAccountData.totalConsumption) - parseFloat(previousMonthAccountData.totalConsumption)) / parseFloat(previousMonthAccountData.totalConsumption)) * 100;
                    if (result >= 5) {
                        plusList.push(thisMonthAccountData);
                    }
                }
                else if (!parseInt(previousMonthAccountData.totalConsumption) && parseInt(thisMonthAccountData.totalConsumption)) {
                    plusList.push(thisMonthAccountData);
                }
                else if (parseFloat(thisMonthAccountData.totalConsumption) && parseFloat(previousMonthAccountData.totalConsumption) && parseFloat(thisMonthAccountData.totalConsumption) > parseFloat(previousMonthAccountData.totalConsumption)) {
                    const result = ((parseFloat(previousMonthAccountData.totalConsumption) - parseFloat(thisMonthAccountData.totalConsumption)) / parseFloat(previousMonthAccountData.totalConsumption)) * 100;
                    if ((result) >= 5) {
                        minusList.push(thisMonthAccountData);
                    }
                } else if (parseFloat(previousMonthAccountData.totalConsumption) && !parseFloat(thisMonthAccountData.totalConsumption)) {
                    minusList.push(thisMonthAccountData);
                }
            }
        };

        for (let i = 0; i < first.length; i++) {
            //*: Total Load
            LTtotalLoad[5] += parseInt(first[i].maxDemand);
            //*: Total Sanctioned Load
            LTtotalSanctionedLoad[5] += parseInt(first[i].sanctionLoad);
            //*: Total Energy
            LTtotalConsumption[5] += parseInt(first[i].totalConsumption);
            //*: Total Bill
            LTtotalBill[5] += parseInt(first[i].billingDemand);
            //*: Total Fixed Charge
            LTtotalFixedCharge[5] += parseInt(first[i].fixedCharge);
            //*: Total Connections with zero bill (totalAmountAfterDueDate)
            if (parseInt(first[i].billingDemand) === 0) {
                LTzeroBillList[5].push(first[i]);
            };
            //*: Total PDC Connections
            if (first[i].billType === 'PDC') {
                LTtotalPDCList[5].push(first[i]);
            };
            //*: Total Power Factor Less than 0.8 Connections
            if (0 < parseFloat(first[i].powerFactor) && parseFloat(first[i].powerFactor) < 0.8) {
                LTtotalPowerFactorLessThan9List[5].push(first[i]);
                LTpowerFactorLessThan9TotalWeldingCharge[5] += parseFloat(first[i].weldingSurcharge);
            };
            //*: Total Monthly surchargePayableOnDelay
            LTsurchargePayableOnDelay[5] += parseInt(first[i].latePayCharge);
            //*: Total MDSL Analysis List
            mdsl(first, LTplusMDSLList[5], LTminusMDSLList[5], i);
            //*: Total Monthly currentMonthBill
            LTcurrentMonthTotalBill[5] += parseInt(first[i].currentPayableAmount);
            //*: List with zero consumption connections
            if (!parseInt(first[i].totalConsumption) && first[i].billType !== 'PDC') {
                LTzeroList[5].push(first[i]);
                LTzeroFixedCharge[5] += parseInt(first[i].fixedCharge);
                LTzeroSecurityAmountDeposit[5] += parseInt(first[i].securityAmountDeposit);
            };
            //*: Total connections with PDC securityDepositAmount
            if (first[i].billType === 'PDC') {
                LTPDCSecurityAmountDeposit[5] += parseInt(first[i].securityAmountDeposit);
            };
            //*: Total consumption variation with 5 difference List
            fiveVariation(first, second, LTplus5List[4], LTminus5List[4], i);
            fiveVariation(second, third, LTplus5List[3], LTminus5List[3], i);
            fiveVariation(third, fourth, LTplus5List[2], LTminus5List[2], i);
            fiveVariation(fourth, fifth, LTplus5List[1], LTminus5List[1], i);
            fiveVariation(fifth, sixth, LTplus5List[0], LTminus5List[0], i);
        };

        for (let i = 0; i < second.length; i++) {
            //*: Total Load
            LTtotalLoad[4] += parseInt(second[i].maxDemand);
            //*: Total Sanctioned Load
            LTtotalSanctionedLoad[4] += parseInt(second[i].sanctionLoad);
            //*: Total Energy
            LTtotalConsumption[4] += parseInt(second[i].totalConsumption);
            //*: Total Bill
            LTtotalBill[4] += parseInt(second[i].billingDemand);
            //*: Total Fixed Charge
            LTtotalFixedCharge[4] += parseInt(second[i].fixedCharge);
            //*: Total Connections with zero bill (totalAmountAfterDueDate)
            if (parseInt(second[i].billingDemand) === 0) {
                LTzeroBillList[4].push(second[i]);
            };
            //*: Total PDC Connections
            if (second[i].billType === 'PDC') {
                LTtotalPDCList[4].push(second[i]);
            };
            //*: Total Power Factor Less than 0.8 Connections
            if (0 < parseFloat(second[i].powerFactor) && parseFloat(second[i].powerFactor) < 0.8) {
                LTtotalPowerFactorLessThan9List[4].push(second[i]);
                LTpowerFactorLessThan9TotalWeldingCharge[4] += parseFloat(second[i].weldingSurcharge);
            };
            //*: Total Monthly surchargePayableOnDelay
            LTsurchargePayableOnDelay[4] += parseInt(second[i].latePayCharge);
            //*: Total MDSL Analysis List
            mdsl(second, LTplusMDSLList[4], LTminusMDSLList[4], i);
            //*: Total Monthly currentMonthBill
            LTcurrentMonthTotalBill[4] += parseInt(second[i].currentPayableAmount);
            //*: List with zero consumption connections
            if (!parseInt(second[i].totalConsumption) && second[i].billType !== 'PDC') {
                LTzeroList[4].push(second[i]);
                LTzeroFixedCharge[4] += parseInt(second[i].fixedCharge);
                LTzeroSecurityAmountDeposit[4] += parseInt(second[i].securityAmountDeposit);
            }
            //*: Total connections with PDC securityDepositAmount
            if (second[i].billType === 'PDC') {
                LTPDCSecurityAmountDeposit[4] += parseInt(second[i].securityAmountDeposit);
            };


        };


        for (let i = 0; i < third.length; i++) {
            //*: Total Load
            LTtotalLoad[3] += parseInt(third[i].maxDemand);
            //*: Total Sanctioned Load
            LTtotalSanctionedLoad[3] += parseInt(third[i].sanctionLoad);
            //*: Total Energy
            LTtotalConsumption[3] += parseInt(third[i].totalConsumption);
            //*: Total Bill
            LTtotalBill[3] += parseInt(third[i].billingDemand);
            //*: Total Fixed Charge
            LTtotalFixedCharge[3] += parseInt(third[i].fixedCharge);
            //*: Total Connections with zero bill (totalAmountAfterDueDate)
            if (parseInt(third[i].billingDemand) === 0) {
                LTzeroBillList[3].push(third[i]);
            };
            //*: Total PDC Connections
            if (third[i].billType === 'PDC') {
                LTtotalPDCList[3].push(third[i]);
            };
            //*: Total Power Factor Less than 0.8 Connections
            if (0 < parseFloat(third[i].powerFactor) && parseFloat(third[i].powerFactor) < 0.8) {
                LTtotalPowerFactorLessThan9List[3].push(third[i]);
                LTpowerFactorLessThan9TotalWeldingCharge[3] += parseFloat(third[i].weldingSurcharge);
            };
            //*: Total Monthly surchargePayableOnDelay
            LTsurchargePayableOnDelay[3] += parseInt(third[i].latePayCharge);
            //*: Total MDSL Analysis List
            mdsl(third, LTplusMDSLList[3], LTminusMDSLList[3], i);
            //*: Total Monthly currentMonthBill
            LTcurrentMonthTotalBill[3] += parseInt(third[i].currentPayableAmount);
            //*: List with zero consumption connections
            if (!parseInt(third[i].totalConsumption) && third[i].billType !== 'PDC') {
                LTzeroList[3].push(third[i]);
                LTzeroFixedCharge[3] += parseInt(third[i].fixedCharge);
                LTzeroSecurityAmountDeposit[3] += parseInt(third[i].securityAmountDeposit);
            }
            //*: Total connections with PDC securityDepositAmount
            if (third[i].billType === 'PDC') {
                LTPDCSecurityAmountDeposit[3] += parseInt(third[i].securityAmountDeposit);
            };


        };


        for (let i = 0; i < fourth.length; i++) {
            //*: Total Load
            LTtotalLoad[2] += parseInt(fourth[i].maxDemand);
            //*: Total Sanctioned Load
            LTtotalSanctionedLoad[2] += parseInt(fourth[i].sanctionLoad);
            //*: Total Energy
            LTtotalConsumption[2] += parseInt(fourth[i].totalConsumption);
            //*: Total Bill
            LTtotalBill[2] += parseInt(fourth[i].billingDemand);
            //*: Total Fixed Charge
            LTtotalFixedCharge[2] += parseInt(fourth[i].fixedCharge);
            //*: Total Connections with zero bill (totalAmountAfterDueDate)
            if (parseInt(fourth[i].billingDemand) === 0) {
                LTzeroBillList[2].push(fourth[i]);
            };
            //*: Total PDC Connections
            if (fourth[i].billType === 'PDC') {
                LTtotalPDCList[2].push(fourth[i]);
            };
            //*: Total Power Factor Less than 0.8 Connections
            if (0 < parseFloat(fourth[i].powerFactor) && parseFloat(fourth[i].powerFactor) < 0.8) {
                LTtotalPowerFactorLessThan9List[2].push(fourth[i]);
                LTpowerFactorLessThan9TotalWeldingCharge[2] += parseFloat(fourth[i].weldingSurcharge);
            };
            //*: Total Monthly surchargePayableOnDelay
            LTsurchargePayableOnDelay[2] += parseInt(fourth[i].latePayCharge);
            //*: Total MDSL Analysis List
            mdsl(fourth, LTplusMDSLList[2], LTminusMDSLList[2], i);
            //*: Total Monthly currentMonthBill
            LTcurrentMonthTotalBill[2] += parseInt(fourth[i].currentPayableAmount);
            //*: List with zero consumption connections
            if (!parseInt(fourth[i].totalConsumption) && fourth[i].billType !== 'PDC') {
                LTzeroList[2].push(fourth[i]);
                LTzeroFixedCharge[2] += parseInt(fourth[i].fixedCharge);
                LTzeroSecurityAmountDeposit[2] += parseInt(fourth[i].securityAmountDeposit);
            }
            //*: Total connections with PDC securityDepositAmount
            if (fourth[i].billType === 'PDC') {
                LTPDCSecurityAmountDeposit[2] += parseInt(fourth[i].securityAmountDeposit);
            };


        };
        for (let i = 0; i < fifth.length; i++) {
            //*: Total Load
            LTtotalLoad[1] += parseInt(fifth[i].maxDemand);
            //*: Total Sanctioned Load
            LTtotalSanctionedLoad[1] += parseInt(fifth[i].sanctionLoad);
            //*: Total Energy
            LTtotalConsumption[1] += parseInt(fifth[i].totalConsumption);
            //*: Total Bill
            LTtotalBill[1] += parseInt(fifth[i].billingDemand);
            //*: Total Fixed Charge
            LTtotalFixedCharge[1] += parseInt(fifth[i].fixedCharge);
            //*: Total Connections with zero bill (totalAmountAfterDueDate)
            if (parseInt(fifth[i].billingDemand) === 0) {
                LTzeroBillList[1].push(fifth[i]);
            };
            //*: Total PDC Connections
            if (fifth[i].billType === 'PDC') {
                LTtotalPDCList[1].push(fifth[i]);
            };
            //*: Total Power Factor Less than 0.8 Connections
            if (0 < parseFloat(fifth[i].powerFactor) && parseFloat(fifth[i].powerFactor) < 0.8) {
                LTtotalPowerFactorLessThan9List[1].push(fifth[i]);
                LTpowerFactorLessThan9TotalWeldingCharge[1] += parseFloat(fifth[i].weldingSurcharge);
            };
            //*: Total Monthly surchargePayableOnDelay
            LTsurchargePayableOnDelay[1] += parseInt(fifth[i].latePayCharge);
            //*: Total MDSL Analysis List
            mdsl(fifth, LTplusMDSLList[1], LTminusMDSLList[1], i);
            //*: Total Monthly currentMonthBill
            LTcurrentMonthTotalBill[1] += parseInt(fifth[i].currentPayableAmount);
            //*: List with zero consumption connections
            if (!parseInt(fifth[i].totalConsumption) && fifth[i].billType !== 'PDC') {
                LTzeroList[1].push(fifth[i]);
                LTzeroFixedCharge[1] += parseInt(fifth[i].fixedCharge);
                LTzeroSecurityAmountDeposit[1] += parseInt(fifth[i].securityAmountDeposit);
            }
            //*: Total connections with PDC securityDepositAmount
            if (fifth[i].billType === 'PDC') {
                LTPDCSecurityAmountDeposit[1] += parseInt(fifth[i].securityAmountDeposit);
            };


        };
        for (let i = 0; i < sixth.length; i++) {
            //*: Total Load
            LTtotalLoad[0] += parseInt(sixth[i].maxDemand);
            //*: Total Sanctioned Load
            LTtotalSanctionedLoad[0] += parseInt(sixth[i].sanctionLoad);
            //*: Total Energy
            LTtotalConsumption[0] += parseInt(sixth[i].totalConsumption);
            //*: Total Bill
            LTtotalBill[0] += parseInt(sixth[i].billingDemand);
            //*: Total Fixed Charge
            LTtotalFixedCharge[0] += parseInt(sixth[i].fixedCharge);
            //*: Total Connections with zero bill (totalAmountAfterDueDate)
            if (parseInt(sixth[i].billingDemand) === 0) {
                LTzeroBillList[0].push(sixth[i]);
            };
            //*: Total PDC Connections
            if (sixth[i].billType === 'PDC') {
                LTtotalPDCList[0].push(sixth[i]);
            };
            //*: Total Power Factor Less than 0.8 Connections
            if (0 < parseFloat(sixth[i].powerFactor) && parseFloat(sixth[i].powerFactor) < 0.8) {
                LTtotalPowerFactorLessThan9List[0].push(sixth[i]);
                LTpowerFactorLessThan9TotalWeldingCharge[0] += parseFloat(sixth[i].weldingSurcharge);
            };
            //*: Total Monthly surchargePayableOnDelay
            LTsurchargePayableOnDelay[0] += parseInt(sixth[i].latePayCharge);
            //*: Total MDSL Analysis List
            mdsl(sixth, LTplusMDSLList[0], LTminusMDSLList[0], i);
            //*: Total Monthly currentMonthBill
            LTcurrentMonthTotalBill[0] += parseInt(sixth[i].currentPayableAmount);
            //*: List with zero consumption connections
            if (!parseInt(sixth[i].totalConsumption) && sixth[i].billType !== 'PDC') {
                LTzeroList[0].push(sixth[i]);
                LTzeroFixedCharge[0] += parseInt(sixth[i].fixedCharge);
                LTzeroSecurityAmountDeposit[0] += parseInt(sixth[i].securityAmountDeposit);
            }
            //*: Total connections with PDC securityDepositAmount
            if (sixth[i].billType === 'PDC') {
                LTPDCSecurityAmountDeposit[0] += parseInt(sixth[i].securityAmountDeposit);
            };

        };


        const date = new Date().getMonth() + 1;


        function extractMonthNumberLT(dateString) {
            const dateParts = dateString.split('-');
            const monthNumber = parseInt(dateParts[1], 10);
            return monthNumber;
        };

        function extractMonthNumberHT(dateString) {
            const monthString = dateString.substring(4, 6);
            const monthNumber = parseInt(monthString, 10);
            return monthNumber;
        };

        const sequenceLT = sortDataSequence(extractMonthNumberLT(first[0].billDate) === date ? 0 : 1).reverse().map(x => {
            return x.replace('20', '');
        });

        const sequenceHT = sortDataSequence(extractMonthNumberHT(HTfirst[0].billMonthYear) === date ? 0 : 1).reverse().map(x => {
            return x.replace('20', '');
        });

        function sortObject(list) {
            return list.map((object) => {
                return Object.keys(object).sort().reduce((objEntries, key) => {
                    objEntries[key] = object[key];
                    return objEntries;
                }, {});
            });
        };

        return {
            //!: HT
            HTupdatedFirst,
            HTtotalLoad,
            HTtotalContractDemand,
            HTtotalConsumption,
            HTtotalBill,
            HTtotalFixedCharges,
            HTplusMDSLList,
            HTminusMDSLList,
            HTplus5List,
            HTminus5List,
            HTtotalPowerFactorLessThan9List,
            HTpowerFactorLessThan9TotalWeldingCharge,
            HTsurchargePayableOnDelay,
            HTcurrentMonthTotalBill,
            HTfirst,
            HTsecond,
            HTthird,
            HTfourth,
            HTfifth,
            HTsixth,
            //!: Common
            sequenceLT,
            sequenceHT,
            sortObject,
            //!: LT
            LTtotalLoad,
            LTtotalSanctionedLoad,
            LTzeroBillList,
            LTtotalFixedCharge,
            LTplusMDSLList,
            LTminusMDSLList,
            LTplus5List,
            LTminus5List,
            LTtotalPowerFactorLessThan9List,
            LTpowerFactorLessThan9TotalWeldingCharge,
            LTsurchargePayableOnDelay,
            LTzeroList,
            LTzeroFixedCharge,
            LTzeroSecurityAmountDeposit,
            LTtotalConsumption,
            LTtotalBill,
            LTtotalPDCList,
            LTcurrentMonthTotalBill,
            LTPDCSecurityAmountDeposit,
            first,
            second,
            third,
            fourth,
            fifth,
            sixth,
        };
    } catch (error) {
        console.error('Error', error.message);
    }
};


export default sortedData;



