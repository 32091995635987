import ConnectionData from "../ConnectionData/index";
import { useState } from "react";
import ConnectionConsumptionChart from '../ConumptionChart/index';


function Zero({ data, list }) {
    list = list.sort(function (a, b) {
        const nameA = a.divisionName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.divisionName.toUpperCase(); // ignore upper and lowercase
        if (nameA > nameB) {
            return 1;
        }
        if (nameA < nameB) {
            return -1;
        }

        // names must be equal
        return 0;
    });;
    const [connectionNumber, setConnectionNumber] = useState(list[0]);

    return (
        <>
            <table id="monthlyDetailsTable" className="table table-hover table-secondary table-bordered table-striped border border-secondary mt-3 pt-5">

                {/* <thead className='table-dark'> */}
                <thead>
                    <tr>
                        <th scope="col">IVRS Number</th>
                        <th scope="col">Full Detail</th>
                        <th scope="col">Distribution Center</th>
                        <th scope="col">Tariff Code</th>
                        <th scope="col">Energy Charge</th>
                        <th scope="col">Previous Month Consumption</th>
                        <th scope="col">This Month Consumption</th>
                        <th scope="col">Percentage Variation in Consumption</th>
                    </tr>
                </thead>

                <tbody>

                    {
                        list.map((x) => {
                            return (
                                <tr>
                                    <td>{x.consumerNo}</td>
                                    <td>
                                        <button onClick={() => setConnectionNumber(x)} className="btn btn-secondary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                                            Details
                                        </button>
                                    </td>
                                    <td>{x.divisionName}</td>
                                    <td>{x.tariffSubCategoryCode}</td>
                                    <td>{x.energyCharges}</td>
                                    <td>{x.previousMonthConsumption}</td>
                                    <td>{x.totalUnitsKwh}</td>
                                    <td>{x.percentageVariation}%</td>
                                </tr>
                            )
                        })
                    }

                </tbody >
            </table >
            <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby={connectionNumber.consumerNo}>
                <div className="d-flex justify-content-center mt-3">
                    <button type="button" className="btn text-white" style={{ "backgroundColor": "grey" }} data-bs-dismiss="offcanvas" aria-label='Close'>Close</button>
                </div>
                <div className="offcanvas-header d-flex justify-content-center">
                    <h5 className="offcanvas-title" id={connectionNumber.consumerNo}>IVRS Number: {connectionNumber.consumerNo}
                    </h5>
                </div>
                <div className="offcanvas-body">
                    <ConnectionConsumptionChart data={data} x={connectionNumber} />
                    <ConnectionData x={connectionNumber} />
                </div>
            </div>
        </>
    )
}

export default Zero;