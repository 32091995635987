import TableHT from './table';
import BeforeLogin from './../../BeforeLogin/BeforeLogin';

function HTAnalysis({ data, existingUser }) {
    return (
        <>
            {
                existingUser ?
                    <>
                        <section className="container" id='login-section'>
                            <div className="container-fluid d-flex-justify-content-center py-5 h-100">
                                <h1 className='d-flex justify-content-center mb-3'>HT Analysis</h1>
                                <TableHT data={data} />
                            </div>
                        </section>
                    </>
                    :
                    <BeforeLogin />
            }
        </>
    )
}

export default HTAnalysis;